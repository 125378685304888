import { useEnterpriseEnrollmentStatus } from 'src/api/queries/enterpriseEnrollment';
import { Step, Stepper } from 'src/components/Stepper';
import { URLS } from 'src/utils/urls/urls';

import { getEnrollmentState } from '../utils/getEnrollmentState';
import { useHasLinkedAccounts } from '../utils/useHasLinkedAccounts';

export function EnterpriseSteps() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const hasLinkedStatus = useHasLinkedAccounts();
  const state = getEnrollmentState(enrollmentStatus.data);

  const canFinalizeEnrollment = hasLinkedStatus.data?.isLinked;

  return (
    <Stepper>
      <Step
        checked={state.hasInitiatedEnrollment}
        to={URLS.ENTERPRISE_CREATE_ORGANIZATION}
      >
        Create organization
      </Step>

      <Step
        disabled={!state.hasInitiatedEnrollment}
        checked={state.hasVerifiedDomain}
        to={URLS.ENTERPRISE_VERIFY_DOMAIN}
      >
        Verify domain
      </Step>

      <Step
        disabled={!state.hasInitiatedEnrollment || !state.hasVerifiedDomain}
        checked={state.hasConfiguredSaml}
        to={URLS.ENTERPRISE_SETUP_SAML}
      >
        Set up SAML
      </Step>

      <Step
        disabled={
          !state.hasInitiatedEnrollment ||
          !state.hasVerifiedDomain ||
          !state.hasConfiguredSaml
        }
        checked={!!canFinalizeEnrollment}
        to={URLS.ENTERPRISE_VERIFY_SAML}
      >
        Verify SAML
      </Step>

      <Step
        disabled={!canFinalizeEnrollment}
        checked={state.hasFinalized}
        to={URLS.ENTERPRISE_CONFIRM_SETUP}
      >
        Complete
      </Step>
    </Stepper>
  );
}
