import { useEffect, useMemo, useState } from 'react';

import { Typography } from '@remarkable/ark-web';
import {
  ColumnDef,
  createColumnHelper,
  FilterFnOption,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useStripeSubscription } from 'src/api/queries';
import { Input, SomethingWentWrong, Table } from 'src/components';
import { CONFIG } from 'src/config';
import {
  createSelectColumn,
  useMultiRowSelect,
} from 'src/utils/createTableSelectColumn';

import {
  isInvitationMember,
  isSubscriptionMember,
  Member,
  useMembersList,
} from '../utils/useMembersList';
import { getInvitationStatusText, InvitationStatus } from './InvitationStatus';
import { InviteToSeatsButton } from './InviteToSeatsButton';
import { MemberActionMenu } from './MemberActionMenu';
import { MemberInvitationStatusBar } from './MemberInvitationStatusBar';
import { MembersBulkActionPanel } from './MembersBulkActionPanel';
import { getSeatStatusText, SeatStatus } from './SeatStatus';

const columnHelper = createColumnHelper<Member>();

export const MembersTable = () => {
  const members = useMembersList();
  const subscription = useStripeSubscription();

  const columns = useMemo(
    () =>
      [
        CONFIG.EnableSeatsBulkActions && createSelectColumn(columnHelper),
        columnHelper.accessor('status', {
          id: 'status-bar',
          header: '',
          meta: {
            truncate: false, // prevents overflow hidden
            colWidth: 'w-0',
          },
          cell: (info) => (
            <MemberInvitationStatusBar member={info.row.original} />
          ),
        }),
        columnHelper.accessor('title', { header: 'Title' }),
        columnHelper.accessor('role', {
          header: 'Role',
          meta: { colWidth: 'w-[19%]', truncate: false },
        }),
        columnHelper.accessor(
          // Even though this isn't rendered, we want the status text here as well
          // so that it can be filtered and sorted on
          (d) => {
            if (d.type === 'invitation') {
              const statusText = getInvitationStatusText(d.data);

              return `${statusText.mainStatus ?? ''} ${
                statusText.subStatus ?? ''
              }`;
            }

            const statusText = getSeatStatusText(d.data, subscription.data);

            return `${statusText.mainStatus} ${statusText.subStatus ?? ''}`;
          },
          {
            header: 'Status',
            meta: { colWidth: 'w-[20%] lm:w-[180px] ll:w-1/4' },
            cell: (info) => {
              if (isInvitationMember(info.row.original)) {
                return <InvitationStatus invitation={info.row.original.data} />;
              }

              if (isSubscriptionMember(info.row.original)) {
                return (
                  <SeatStatus
                    member={info.row.original.data}
                    subscription={subscription.data}
                  />
                );
              }

              return info.getValue();
            },
          }
        ),
        columnHelper.display({
          header: 'Edit',
          meta: {
            align: 'center',
            colWidth: 'w-[50px]',
            truncate: false,
          },
          cell: (info) => <MemberActionMenu member={info.row.original} />,
        }),
      ].filter(Boolean),
    [CONFIG.EnableSeatsBulkActions]
  );

  const [globalFilterFn, setGlobalFilterFn] =
    useState<FilterFnOption<Member>>('includesString');

  const table = useReactTable({
    data: members.data,
    columns: columns as ColumnDef<Member>[],
    meta: useMultiRowSelect<Member>(),
    getRowId: ({ id }) => id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn,
    renderFallbackValue: () => '-',
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const globalFilter = table.getState().globalFilter;

  // Toggle on case sensitivity if the filter has uppercase letters.
  // This makes it possible to separate status "Inactive" from "Active" by
  // using uppercase "A" in the filter input. So a filter with the string
  // "active" would match both, but "Active" would exclude "Inactive".
  useEffect(() => {
    if (typeof globalFilter !== 'string') return;

    const hasUpperCaseLetters = globalFilter === globalFilter.toLowerCase();

    // Only update the state if it is different (performance drops noticably
    // if we don't do this optimisation, even in small lists).
    if (hasUpperCaseLetters && globalFilterFn !== 'includesString') {
      setGlobalFilterFn('includesString');
    } else if (
      !hasUpperCaseLetters &&
      globalFilterFn !== 'includesStringSensitive'
    ) {
      setGlobalFilterFn('includesStringSensitive');
    }
  }, [globalFilter]);

  if (members.isError) return <SomethingWentWrong />;

  return (
    <div className="flex flex-col gap-24 py-16">
      <div className="flex flex-col-reverse justify-between gap-16 ls:flex-row">
        <Input
          id="search"
          placeholder="Search"
          className="w-full ls:max-w-[300px]"
          onChange={(e) => table.setGlobalFilter(e.currentTarget.value)}
        />

        <InviteToSeatsButton />
      </div>

      <Table
        table={table}
        data-cy="members-table"
        className="hidden ls:table"
      />

      <ul
        data-cy="members-list-mobile"
        className="border-t border-gray-100 ls:hidden"
      >
        {table.getRowModel().rows.map((row) => (
          // We use the table's row state because it is already filtered and sorted
          <li
            data-cy={row.id}
            key={row.id}
            className="border-b border-gray-100 py-12"
          >
            <div className="flex items-center justify-between gap-16">
              <div className="flex min-w-0 flex-col">
                <Typography variant="body-sm-regular" className="truncate">
                  {row.original.title}
                </Typography>

                <div className="opacity-70">
                  {row.original.type === 'invitation' && (
                    <InvitationStatus invitation={row.original.data} />
                  )}

                  {row.original.type === 'subscription' && (
                    <Typography variant="body-sm-regular" className="italic">
                      {row.original.status}
                    </Typography>
                  )}
                </div>
              </div>

              <MemberActionMenu member={row.original} />
            </div>
          </li>
        ))}
      </ul>

      <MembersBulkActionPanel table={table} />
    </div>
  );
};
