import { CONFIG } from 'src/config';

import { addAuthHeaders } from '../addAuthHeaders';
import { catchHttpError, onStatus } from '../catchHttpError';
import { storeApiClient } from '../clients/storeApiClient';
import { createApiClient } from '../createApiClient';
import {
  AccountInfoResponse,
  BillingFormType,
  BillingInfoAllowedResponse,
  CartDetailsResponse,
  ChangeEmailResponse,
  CheckoutCartTaxBody,
  CheckoutTypeParam,
  CheckoutsCheckoutResponse,
  CheckoutsVerifyIntentResponse,
  CreateSeatInvitationLinkResponse,
  CreateSetupIntentResponse,
  GetFeatureFlagSubsolarCanaryResponse,
  GetSubscriptionManagementTypeResponse,
  InvalidEmailException,
  InvoiceCheckoutRequestBody,
  PaymentHistoryResponse,
  PaymentInterval,
  ProcessedActivationResponse,
  ProductPricesResponse,
  RequestEmailChangeError,
  SeatInvitationsResponse,
  SetupResponse,
  StripeTestClockResponse,
  SubscriptionInvitationResponse,
  SubscriptionManagementType,
  SubscriptionPaymentInterval,
  SubscriptionRole,
  UpdateEmailBody,
  VerifySetupIntentResponse,
} from './storeApi.types';

// =============== API Client ================

/** @deprecated use storeApiClient instead */
const storeApi = createApiClient({
  prefixUrl: CONFIG.StoreAPIURL,
  hooks: { beforeRequest: [addAuthHeaders] },
  credentials: 'include',
});

// TODO: Replace this with a storeApiPublicClient, if needed
const publicStoreApi = createApiClient({
  prefixUrl: CONFIG.StoreAPIURL,
  credentials: 'include',
});

// SUBSCRIPTION ---------------------------------------------------------------

export const getSubscription = () =>
  storeApiClient
    .GET('/v2/myrm/subscriptions')
    .then((res) => res?.data?.data ?? null);

export const cancelSubscription = (vars: { subscriptionId: string }) =>
  storeApiClient.DELETE('/v2/myrm/subscription/{subscriptionUUID}', {
    params: { path: { subscriptionUUID: vars.subscriptionId } },
  });

export const abortCancelSubscription = (vars: { subscriptionId: string }) =>
  storeApiClient.PUT('/v2/myrm/subscription/{subscriptionUUID}', {
    params: { path: { subscriptionUUID: vars.subscriptionId } },
    body: { cancel: false },
  });

export const updateSubscriptionManagementTypeAllowed = (vars: {
  subscriptionId: string;
  managementType: SubscriptionManagementType;
}) =>
  storeApi
    .get(
      `v1/myrm/subscription/${vars.subscriptionId}/management-type/${vars.managementType}`
    )
    .json<GetSubscriptionManagementTypeResponse>()
    .then((res) => res.data);

export const updateSubscriptionManagementType = (vars: {
  subscriptionId: string;
  managementType: SubscriptionManagementType;
}) =>
  storeApi
    .post(
      `v1/myrm/subscription/${vars.subscriptionId}/management-type/${vars.managementType}`
    )
    .text();

// SUBSCRIPTION PAYMENT -------------------------------------------------------

export const updatePaymentInterval = (vars: {
  subscriptionId: string;
  interval: SubscriptionPaymentInterval;
}) =>
  storeApiClient.PUT(
    '/v2/myrm/subscription/{subscriptionUUID}/payment-interval',
    {
      params: { path: { subscriptionUUID: vars.subscriptionId } },
      body: { paymentInterval: vars.interval },
    }
  );

export const changePaymentMethodSetupIntent = (vars: {
  subscriptionId: string;
}) =>
  storeApi
    .post(`v2/myrm/subscription/${vars.subscriptionId}/setup-intent`)
    .json<CreateSetupIntentResponse>()
    .then((res) => res.data);

export const changePaymentMethodVerifySetupIntent = (vars: {
  subscriptionId: string;
  clientSecret: string;
}) =>
  storeApi
    .post(`v2/myrm/subscription/${vars.subscriptionId}/setup-intent/verify`, {
      json: {
        data: { clientSecret: vars.clientSecret },
      },
      timeout: 40000,
    })
    .json<VerifySetupIntentResponse>()
    .then((res) => res.data);

// SUBSCRIPTION MEMBERS -------------------------------------------------------

export const getSubscriptionMembers = (subscriptionUUID: string) =>
  storeApiClient
    .GET('/v1/myrm/subscription/{subscriptionUUID}/members', {
      params: { path: { subscriptionUUID } },
    })
    .then((res) => res?.data?.data ?? null);

export const createSeatForMember = (vars: {
  subscriptionId: string;
  memberId: string;
}) =>
  storeApi
    .post(
      `v1/myrm/subscription/${vars.subscriptionId}/member/${vars.memberId}/seat`
    )
    .text();

export const removeSeatForMember = (vars: {
  subscriptionId: string;
  memberId: string;
}) =>
  storeApi
    .delete(
      `v1/myrm/subscription/${vars.subscriptionId}/member/${vars.memberId}/seat`
    )
    .text();

export const updateSubscriptionMemberRole = (vars: {
  subscriptionId: string;
  subscriptionRoleId: string;
  newRole: string;
}) =>
  storeApiClient.PUT(
    '/v1/myrm/subscription/{subscriptionUUID}/member/{subscriptionRoleUUID}',
    {
      params: {
        path: {
          subscriptionUUID: vars.subscriptionId,
          subscriptionRoleUUID: vars.subscriptionRoleId,
        },
      },
      body: { role: vars.newRole },
    }
  );

export const removeUserFromSubscription = (vars: {
  subscriptionRoleId: string;
  subscriptionId: string;
}) =>
  storeApi
    .delete(
      `v1/myrm/subscription/${vars.subscriptionId}/member/${vars.subscriptionRoleId}`
    )
    .text();

// SUBSCRIPTION INVITES -------------------------------------------------------

export const getInvitations = (vars: { subscriptionId: string }) =>
  storeApi
    .get(`v1/myrm/subscription/${vars.subscriptionId}/invitations`)
    .json<SeatInvitationsResponse>()
    .then((res) => res.data);

export const createSeatInvitationEmail = (vars: {
  subscriptionId: string;
  email: string;
  targetRole: SubscriptionRole;
}) =>
  storeApiClient.PUT(`/v1/myrm/subscription/{subscriptionUUID}/invite/email`, {
    params: {
      path: {
        subscriptionUUID: vars.subscriptionId,
      },
    },
    body: { email: vars.email, targetRole: vars.targetRole },
  });

export const createSeatInvitationLink = (vars: { subscriptionId: string }) =>
  storeApi
    .put(`v1/myrm/subscription/${vars.subscriptionId}/invite/link`)
    .json<CreateSeatInvitationLinkResponse>()
    .then((res) => res.data);

export const getSeatInvitation = (vars: { invitationTokenUUID: string }) =>
  storeApi
    .get(`v1/myrm/invitation/seat/${vars.invitationTokenUUID}`)
    .json<SubscriptionInvitationResponse>()
    .then((res) => res.data);

export const acceptSeatInvitation = (vars: { invitationTokenUUID: string }) =>
  storeApi
    .put(`v1/myrm/invitation/seat/${vars.invitationTokenUUID}`, {
      json: { accept: true },
    })
    .text();

export const declineSeatInvitation = (vars: { invitationTokenUUID: string }) =>
  storeApi
    .put(`v1/myrm/invitation/seat/${vars.invitationTokenUUID}`, {
      json: { decline: true },
    })
    .text();

export const deleteSeatInvitation = (vars: { invitationTokenUUID: string }) =>
  storeApi.delete(`v1/myrm/invitation/seat/${vars.invitationTokenUUID}`).text();

// BILLING INFO ---------------------------------------------------------------

export const getBillingInfo = () =>
  storeApi
    .get('v1/myrm/account/address')
    .json<BillingInfoAllowedResponse>()
    .then((res) => res.data);

export const updateBillingInfo = (vars: BillingFormType) =>
  storeApi
    .put('v1/myrm/account/address', { json: vars })
    .json<BillingInfoAllowedResponse>()
    .then((res) => res.data);

// PAYMENT ---------------------------------------------------------------------

export const getPaymentHistory = () => {
  return storeApi
    .get(`v2/myrm/payment-history`)
    .json<PaymentHistoryResponse>()
    .then((res) => res.data);
};

// EMAIL ----------------------------------------------------------------------

export const getEmailVerified = () =>
  storeApiClient
    .GET('/v1/myrm/email-verified')
    .then((res) => res?.data?.data ?? null);

export const resendEmailVerification = () =>
  storeApi.post('v1/myrm/resend-email-verification');

export const requestEmailChange = (vars: UpdateEmailBody) =>
  catchHttpError(
    storeApi.post('v1/myrm/email-change', { json: vars }).json(),
    onStatus('BadRequest', async (error) => {
      const response = (await error.response.json()) as RequestEmailChangeError;
      if (response.error === 'Invalid Email') {
        throw new InvalidEmailException(response.context.userMessage);
      }
    })
  );

export const getEmailChangeStatus = () =>
  storeApi.get('v1/myrm/email-change').json<ChangeEmailResponse>();

export const cancelEmailChangeRequest = () =>
  storeApi.delete('v1/myrm/email-change').json();

export const confirmEmailChange = (vars: { token: string }) =>
  storeApi
    .post('v1/myrm/update-email', { json: vars })
    .json<ChangeEmailResponse>()
    .then((res) => res.data);

export const getEmailChangeStatusForToken = (vars: { urlToken: string }) => {
  return storeApi
    .get(`v1/myrm/email-change/${vars.urlToken}`)
    .json<ChangeEmailResponse>()
    .then((res) => res.data);
};

// PRODUCT ---------------------------------------------------------------------

export const getProductPricesPublic = (sku: string) =>
  publicStoreApi
    .get(`v1/myrm/product/${sku}/prices`)
    .json<ProductPricesResponse>()
    .then((res) => res.data);

// CHECKOUT ---------------------------------------------------------------------

export const getCheckoutAllowed = () =>
  storeApiClient
    .GET(`/myrm/checkout/v4/allowed`)
    .then((res) => res?.data?.data ?? null);

export const setupNewCart = <T extends CheckoutTypeParam>(vars: {
  type: T;
  country: string;
  paymentInterval: PaymentInterval;
}) =>
  storeApi
    .post(`myrm/checkout/v3/setup`, {
      json: {
        data: {
          country: vars.country,
          paymentInterval: vars.paymentInterval,
        },
      },
      headers: {
        flow: vars.type,
      },
    })
    .json<SetupResponse>()
    .then<SetupResponse['data']>((res) => res.data);

export const getCartDetails = <T extends CheckoutTypeParam>(vars: {
  type: T;
  id: string;
  paymentInterval: PaymentInterval;
}) =>
  storeApi
    .get(`myrm/checkout/v3/cart/${vars.id}`, {
      headers: {
        flow: vars.type,
        'x-store-payment-interval': vars.paymentInterval,
      },
    })
    .json<CartDetailsResponse>();

export const updateCartDetails = <T extends CheckoutTypeParam>(vars: {
  type: T;
  id: string;
  country: string;
  secret: string;
  paymentInterval: PaymentInterval;
  taxData?: CheckoutCartTaxBody;
}) =>
  storeApi
    .put(`myrm/checkout/v3/cart/${vars.id}`, {
      headers: {
        flow: vars.type,
        'x-store-payment-interval': vars.paymentInterval,
      },
      json: { ...vars.taxData, country: vars.country, secret: vars.secret },
    })
    .json<CartDetailsResponse>();

export const checkoutCart = (vars: {
  type: CheckoutTypeParam;
  data: Record<string, unknown>;
}) =>
  storeApi
    .post(`myrm/checkout/v3/checkout`, {
      headers: {
        flow: vars.type,
      },
      json: { data: vars.data },
      timeout: 40000,
    })
    .json<CheckoutsCheckoutResponse>()
    .then((res) => res.data);

export const updateCartTax = <T extends CheckoutTypeParam>(vars: {
  type: T;
  id: string;
  currency: string; // Redundant soon
  data: CheckoutCartTaxBody;
  secret: string;
  paymentInterval: PaymentInterval;
}) =>
  storeApi
    .put(`myrm/checkout/v3/cart/${vars.id}`, {
      headers: {
        flow: vars.type,
        'x-store-currency': vars.currency, // Redundant soon
        'x-store-payment-interval': vars.paymentInterval,
      },
      json: { ...vars.data, secret: vars.secret },
    })
    .json<CartDetailsResponse>();

export const postCheckoutPaymentVerifyIntent = (vars: {
  type: CheckoutTypeParam;
  payload: {
    order_id: string;
    paymentInterval: PaymentInterval;
    setup_intent_client_secret?: string;
    payment_intent_client_secret?: string;
  };
}) =>
  storeApi
    .post(`myrm/checkout/v3/verify-intent`, {
      headers: {
        flow: vars.type,
      },
      json: {
        data: vars.payload,
      },
      timeout: 40000,
    })
    .json<CheckoutsVerifyIntentResponse>();

// MFA -------------------------------------------------------------------------

export const enrollMFA = () => {
  return storeApi.post(`v1/myrm/account/mfa`);
};

export const resetMFA = () => {
  return storeApi.delete(`v1/myrm/account/mfa`);
};

// ACCOUNT ---------------------------------------------------------------------

export const getAccountInfo = () => {
  return storeApi
    .get(`v1/myrm/account`)
    .json<AccountInfoResponse>()
    .then((res) => res.data);
};

export const updateAccountInfo = (firstName: string, lastName: string) => {
  return storeApi.post(`v1/myrm/account`, {
    json: {
      data: {
        firstName: firstName,
        lastName: lastName,
      },
    },
  });
};

export const unlinkAccount = (unlinkUserId: string, provider: string) =>
  storeApi.put(`v1/myrm/account/unlink`, {
    json: {
      data: {
        unlinkUserId: unlinkUserId,
        provider: provider,
      },
    },
  });

export const deleteAccount = async () =>
  storeApiClient.DELETE('/v1/myrm/account');

// OFFERS --------------------------------

export const getDeviceActivationStatus = (deviceId: string) =>
  storeApi
    .get(`v1/myrm/${deviceId}/activation`)
    .json<ProcessedActivationResponse>();

// FEATURE TOGGLES --------------------------------

export const getFeatureFlagSubsolarCanary = () =>
  storeApi
    .get('/v1/myrm/feature-flag/subsolar-canary')
    .json<GetFeatureFlagSubsolarCanaryResponse>()
    .then((res) => res.data);

// DEV TOOLS --------------------------------

export const getStripeTestClock = () =>
  storeApi
    .get(`v1/myrm/development/test-clock`)
    .json<StripeTestClockResponse>()
    .then((res) => res.data);

export const createStripeTestClock = () =>
  storeApi.post(`v1/myrm/development/test-clock`, {
    timeout: 20000,
  });

export const updateStripeTestClock = (vars: { frozenTime: Date }) =>
  storeApi
    .put(`v1/myrm/development/test-clock`, {
      timeout: 20000,
      json: {
        data: {
          frozenTime: vars.frozenTime.toISOString(),
        },
      },
    })
    .json<StripeTestClockResponse>()
    .then((res) => res.data);

export const updateCollectionMethod = async ({
  subscriptionId,
  payByInvoice,
}: {
  subscriptionId: string;
  payByInvoice: boolean;
}) =>
  storeApiClient.PUT(
    `/v1/myrm/subscription/{subscriptionUUID}/pay-by-invoice`,
    {
      params: {
        path: {
          subscriptionUUID: subscriptionId,
        },
      },
      body: {
        payByInvoice,
      },
    }
  );

export const checkoutWithInvoice = async ({
  body,
}: {
  body: InvoiceCheckoutRequestBody;
}) =>
  storeApiClient.POST(`/myrm/checkout/v1/invoice`, {
    body,
    params: { header: { flow: 'connect-for-business' } },
  });

export const checkoutWithoutCard = async ({
  body,
}: {
  body: InvoiceCheckoutRequestBody;
}) =>
  storeApiClient.POST(`/myrm/checkout/v1/cardless`, {
    body,
    params: { header: { flow: 'dm-offer' } },
  });

export { storeApiClient };
