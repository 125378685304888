import { paths } from 'apis/cloud-enterprise-admin-api';
import createClient from 'openapi-fetch';

import { CONFIG } from 'src/config';

import { addAuthHeader } from './middleware/addAuthHeader';
import { throwOnErrorResponse } from './middleware/throwOnErrorResponse';

const cloudEnterpriseAdminApiClient = createClient<paths>({
  baseUrl: CONFIG.CloudEnterpriseApiUrl,
});

cloudEnterpriseAdminApiClient.use(addAuthHeader, throwOnErrorResponse);

export { cloudEnterpriseAdminApiClient };
