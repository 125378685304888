import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRight } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';
import { getRouteApi } from '@tanstack/react-router';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  useAccountLinkingListener,
  useLoginToLinkableAccount,
} from 'src/api/queries/user';
import { getEmailInfo } from 'src/apps/enterprise/app/utils/getEmailDomain';
import { useHasLinkedAccounts } from 'src/apps/enterprise/app/utils/useHasLinkedAccounts';
import {
  Button,
  Divider,
  FaqSection,
  Feedback,
  Link,
  Sheet,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { FAQ } from 'src/constants/FAQ';
import { capitalize } from 'src/utils/capitalize';
import { URLS } from 'src/utils/urls/urls';
import { userHelpers } from 'src/utils/userHelpers';

import { AccountMigrateConfirm } from './AccountMigrateConfirm';

const routeApi = getRouteApi('/_auth/_layout/account/migrate/');

export function AccountMigrate() {
  const hasLinkedAccounts = useHasLinkedAccounts();
  const accountLinkingListener = useAccountLinkingListener({
    returnUrl: URLS.ACCOUNT_MIGRATE_SUCCESS,
  });
  const loginToLinkableAccount = useLoginToLinkableAccount();
  const { user } = useAuth0();
  const email = getEmailInfo(user?.email ?? '');
  const search = routeApi.useSearch();
  const navigate = routeApi.useNavigate();

  // TODO: Replace these shenanigans with actual company name when it gets available from the backend
  let companyName = capitalize(email.domain.split('.')[0]);
  if (companyName.toLowerCase() === 'remarkable') {
    companyName = 'reMarkable';
  }

  if (accountLinkingListener.isPending || hasLinkedAccounts.isPending) {
    return <Spinner />;
  }

  if (!user) {
    return <SomethingWentWrong />;
  }

  const isSaml = userHelpers.isSAMLUser(user);

  if (search.confirm === true) {
    return <AccountMigrateConfirm />;
  }

  return (
    <AppPage.Container>
      <AppPage.Content className="max-w-lxl py-16 lm:py-32 ll:py-64">
        <Sheet className="items-start gap-24 lm:items-center lm:text-center ll:gap-32 ll:py-64">
          <Typography as="h1" variant="responsive-heading-md">
            Start using your enterprise account
          </Typography>

          <Typography
            variant="body-md-regular"
            className="m-auto w-full max-w-ll"
          >
            {companyName} has enabled enterprise accounts for improved
            management and security. All {email.domain} accounts will be part of
            this change, including yours:
            <br />
            <b className="font-medium">{email.full}</b>
          </Typography>

          <Typography
            variant="body-md-regular"
            className="m-auto w-full max-w-ll"
          >
            Activate your enterprise account to enable single sign-on.
          </Typography>

          {isSaml ? (
            <div className="flex flex-col gap-16">
              <Button
                variant="primary"
                className="w-full lm:w-auto"
                analytics={{
                  location: ComponentLocations.ACCOUNT.MIGRATE_SAML,
                  action: 'continue to confirm page',
                  text: 'Continue',
                }}
                onClick={() => {
                  void navigate({ search: { confirm: true } });
                }}
              >
                <span>Continue</span>
                <ArrowRight />
              </Button>

              <Divider title="OR" />

              <Button
                variant="secondary"
                className="w-full lm:w-auto"
                disabled={loginToLinkableAccount.isPending}
                loading={
                  loginToLinkableAccount.isPending &&
                  !loginToLinkableAccount.variables
                }
                analytics={{
                  location: ComponentLocations.ACCOUNT.MIGRATE_SAML,
                  action: 'login to legacy account',
                  text: 'Login to my original account',
                }}
                onClick={() => {
                  loginToLinkableAccount.mutate({ redirectUrl: URLS.HOME });
                }}
              >
                Login to my original account
              </Button>
            </div>
          ) : (
            <div className="flex w-full flex-col justify-center gap-16 ls:flex-row-reverse">
              <Button
                variant="primary"
                className="w-auto"
                analytics={{
                  location: ComponentLocations.ACCOUNT.MIGRATE_LEGACY,
                  action: 'continue to confirm page',
                  text: 'Continue',
                }}
                onClick={() => {
                  void navigate({ search: { confirm: true } });
                }}
              >
                <span>Continue</span>
                <ArrowRight />
              </Button>

              <Button
                as="a"
                to={URLS.HOME}
                variant="secondary"
                className="w-auto"
              >
                Do this later
              </Button>
            </div>
          )}

          <Typography
            variant="body-sm-regular"
            className="m-auto w-full max-w-ll text-muted"
          >
            If this isn’t your work account, you can change your email address
            in{' '}
            {isSaml ? (
              <Link
                inline
                as="button"
                onClick={() => {
                  tracker.trackEvent(
                    new ButtonClicked({
                      component_location:
                        ComponentLocations.ACCOUNT.MIGRATE_SAML,
                      action:
                        'login to linkable account and go to account page',
                      text: 'account settings',
                    })
                  );

                  loginToLinkableAccount.mutate({
                    redirectUrl: URLS.SETTINGS,
                  });
                }}
              >
                account settings
              </Link>
            ) : (
              <Link inline to={URLS.SETTINGS}>
                account settings
              </Link>
            )}
            .
          </Typography>
        </Sheet>

        <Feedback
          feedbackTargetId="account-linking-landing-v1"
          title="Did you find the information you needed?"
        />

        <FaqSection
          className="mt-64"
          sections={[
            FAQ.enterprise.whatHappensToMyFilesIfIActivateMyEnterpriseAccount(),
            FAQ.enterprise.canAnyoneElseGetAccessToMyDocuments(),
            FAQ.enterprise.whatHappensToTheTermsAndConditionsForRemarkableAccounts(),
            FAQ.enterprise.whatHappensIfIHaveAFreeConnectTrial(),
            FAQ.enterprise.iHaveFreeAccessToConnect(),
          ]}
        />
      </AppPage.Content>
    </AppPage.Container>
  );
}
