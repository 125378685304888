import { useEffect, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';
import { ArrowCircleLeft } from 'phosphor-react';
import toast from 'react-hot-toast';

import { HTTPError } from 'src/api/createApiClient.types';
import {
  useDevices,
  usePollDeviceActivationStatus,
  usePollForNewDevice,
} from 'src/api/queries';
import {
  ONE_TIME_CODE_EXPIRATION_TIME,
  ONE_TIME_CODE_TOO_MANY_REQUESTS_TIMEOUT,
} from 'src/api/queries/devices.config';
import { useOneTimeCode } from 'src/api/queries/oneTimeCode';
import {
  Button,
  Link,
  NotificationBox,
  SomethingWentWrong,
  Spinner,
} from 'src/components';
import { Link2 } from 'src/components/Link2';
import { PairingCompleteToast } from 'src/components/PairingCompleteToast';
import { URLS } from 'src/utils/urls/urls';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';
import { useCountdown } from 'src/utils/useCountdown';

import { MAXIMUM_DEVICES_ALLOWED } from '../utils/constants';
import {
  filterActivePaperTabletClients,
  isClientPaperTablet,
  trackProductConfigured,
} from '../utils/utils';

interface OneTimeCodeProps {
  description: string;
  successRedirectUrl?: string;
  hideBack?: boolean;
  deviceTypeString?: string; // not for remarkable
  allowPairing: boolean;
  hideCopyToClipboard?: boolean;
}

export const OneTimeCode = ({
  description,
  successRedirectUrl,
  hideBack,
  deviceTypeString,
  allowPairing,
  hideCopyToClipboard = false,
}: OneTimeCodeProps) => {
  const auth = useAuth0();

  const countdown = useCountdown(ONE_TIME_CODE_EXPIRATION_TIME);
  const copyToClipboard = useCopyToClipboard();
  const devices = useDevices();
  const navigate = useNavigate();

  // One time code is fetched automatically on mount
  const oneTimeCode = useOneTimeCode();
  // Polling starts whenever the one time code changes
  const pollForNewDevice = usePollForNewDevice(oneTimeCode.data);
  // Device activation status is polled when the new device is a remarkable device
  const deviceActivationStatus = usePollDeviceActivationStatus(
    pollForNewDevice.data
  );

  const activeDevices = useMemo(
    () => filterActivePaperTabletClients(devices.data),
    [devices.data]
  );

  const canConnectMoreDevices = activeDevices.length < MAXIMUM_DEVICES_ALLOWED;

  // ------------------ Handle errors ------------------

  const isTooManyRequests = useMemo(() => {
    if (oneTimeCode.error instanceof HTTPError) {
      return oneTimeCode.error.response.status === 429;
    }

    return false;
  }, [oneTimeCode.error]);

  // Delay refetching of one time code when too many requests
  useEffect(() => {
    if (isTooManyRequests) {
      const timeout = setTimeout(() => {
        void oneTimeCode.refetch();
      }, ONE_TIME_CODE_TOO_MANY_REQUESTS_TIMEOUT);

      return () => clearTimeout(timeout);
    }
  }, [isTooManyRequests]);

  // ------------------ Handle countdown ------------------

  // reset countdown when new code is generated
  useEffect(() => {
    countdown.reset();
  }, [oneTimeCode.data]);

  // Refetch the one time code when the countdown is finished
  useEffect(() => {
    if (pollForNewDevice.data) return; // Don't refetch when we have found a new device

    if (countdown.isFinished) {
      void oneTimeCode.refetch();
    }
  }, [countdown.isFinished, pollForNewDevice.data]);

  // ------------------ Handle pairing ------------------
  useEffect(() => {
    const newDevice = pollForNewDevice.data;
    if (!newDevice) return;

    trackProductConfigured(newDevice, 'device_paired');

    if (!isClientPaperTablet(newDevice) && successRedirectUrl) {
      void navigate({ to: successRedirectUrl });
      return;
    }

    // This is now a remarkable device, so we need to make sure we have the activation status
    if (!deviceActivationStatus.data) return;
    if (deviceActivationStatus.data.retail) {
      void navigate({ to: '/store/retail-offer' });
    } else if (deviceActivationStatus.data.subscriptionOffer) {
      void navigate({ to: '/store/subscription-offer' });
    } else if (deviceActivationStatus.data.connectOffer) {
      void navigate({ to: '/store/connect-offer' });
    } else if (deviceActivationStatus.data.dmOffer) {
      void navigate({ to: '/store/dm-offer' });
    } else if (successRedirectUrl) {
      void navigate({ to: successRedirectUrl });
    }
    toast.custom(PairingCompleteToast, {
      duration: 6500,
      position: 'top-right',
    });
  }, [pollForNewDevice.data, deviceActivationStatus.data]);

  // ------------------ Template ------------------

  if (devices.isPending || oneTimeCode.isPending) {
    return <Spinner />;
  }
  if (devices.isError) {
    return <SomethingWentWrong />;
  }

  if (!canConnectMoreDevices && successRedirectUrl === URLS.DEVICE_REMARKABLE) {
    return (
      <div className="flex flex-col items-center">
        <NotificationBox
          variant="info"
          className="relative mx-8"
          data-cy="connect-warning"
          title="Maximum devices already paired"
        >
          <Typography
            variant="body-sm-regular"
            className="p-0 text-center md:text-left"
          >
            You have already paired the maximum number of devices.
          </Typography>
          <Link2
            as="button"
            variant="secondary"
            className="mt-8"
            size="small"
            to="/device/remarkable"
          >
            Manage devices
          </Link2>
        </NotificationBox>
      </div>
    );
  }

  if (deviceTypeString && !allowPairing) {
    return (
      <div className="flex flex-col items-center">
        <NotificationBox
          variant="warning"
          className="relative mx-8"
          data-cy="connect-warning"
          title="Can't identify reMarkable account"
        >
          <Typography
            variant="body-sm-regular"
            className="p-0 text-center md:text-left"
          >
            To pair the app, please use the email address that you see in
            General settings &gt; Account on your paper tablet.
          </Typography>

          <div className="my-16 flex flex-col gap-24 lm:flex-row">
            <Link2
              as="button"
              variant="primary"
              className="mt-8"
              size="medium"
              to="/device/remarkable/connect"
            >
              Pair device
            </Link2>

            <Button
              variant="secondary"
              className="ml-4 mt-8"
              onClick={() => {
                void auth.logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }}
            >
              Log out
            </Button>
          </div>
        </NotificationBox>
      </div>
    );
  }

  return (
    <div className="mb-16 flex flex-col items-center text-center">
      {hideBack ? null : (
        <div className="w-full">
          <Link2
            search={{ showOtp: false }}
            variant="tertiary-neutral"
            className="mb-12"
          >
            <ArrowCircleLeft weight="fill" size={24} />
            <span>Back</span>
          </Link2>
        </div>
      )}

      <Typography as="h3" variant="heading-md" className="mb-16 mt-8">
        Verification code
      </Typography>

      {hideBack && auth.user && (
        <Typography variant="body-md-regular" className="my-16">
          Logged in as
          <br />
          {auth.user?.email}
        </Typography>
      )}

      <p className="md:max-w-screen-sm">{description}</p>

      {pollForNewDevice.isSuccess &&
      !successRedirectUrl &&
      !isClientPaperTablet(pollForNewDevice.data) ? (
        <NotificationBox
          variant="success"
          title="App successfully paired"
          className="my-24 w-full animate-fade-in-up"
        >
          Go back to start using the app.
        </NotificationBox>
      ) : (
        <>
          {deviceActivationStatus.isPending && deviceActivationStatus.data ? (
            <span
              className="mt-8 flex"
              data-cy="activation-status-loading-indicator"
            >
              <p>Checking activation status...</p>
            </span>
          ) : (
            <span className="mt-8 flex">
              <p className="mr-4">The code is valid for</p>{' '}
              <span data-cy="countdown" className="font-semibold">
                <p>{countdown.formattedTimeLeft}</p>
              </span>
            </span>
          )}
          <p
            data-cy="one-time-code"
            className="mt-24 text-60 min-[320px]:tracking-widest"
          >
            {oneTimeCode.data}
          </p>
        </>
      )}

      {isTooManyRequests ? (
        <NotificationBox
          data-cy="one-time-code-too-many-requests"
          variant="warning"
          title="Too many requests"
        >
          <p>
            You have generated too many codes in a short time. Please wait 10
            seconds for a new code to be generated.
          </p>
        </NotificationBox>
      ) : oneTimeCode.isError ? (
        <NotificationBox
          data-cy="one-time-code-error"
          variant="error"
          title="Could not generate new code"
        >
          <p>
            Unfortunately we could not generate a code. Please try again later
            or contact support if the problem persists.
          </p>
        </NotificationBox>
      ) : (
        deviceActivationStatus.isError && (
          <NotificationBox
            data-cy="device-activation-status-warning"
            variant="warning"
            title="Unable to verify activation status"
          >
            <p>
              We were not able to verify activation status. Please refresh the
              page to verify that pairing was successful.
            </p>
          </NotificationBox>
        )
      )}

      <Link
        as="button"
        data-cy="generate-new-code-button"
        onClick={() => oneTimeCode.refetch()}
        disabled={isTooManyRequests}
      >
        <span className="underline">Get new code</span>
      </Link>

      {copyToClipboard.isError && (
        <NotificationBox
          variant="error"
          className="relative mx-24 mt-20"
          data-cy="copy-to-clipboard-warning"
          title="Could not copy code to clipboard"
        >
          <Typography
            variant="body-sm-regular"
            className="p-0 text-center md:text-left"
          >
            Unfortunately we could not copy the code to your clipboard. Please
            copy it manually.
          </Typography>
        </NotificationBox>
      )}

      <div className="flex gap-16">
        {hideBack && (
          <Button
            variant="tertiary"
            className="mt-24"
            onClick={() => {
              void auth.logout({
                logoutParams: {
                  returnTo: window.location.origin + URLS.PAIR_APP,
                },
              });
            }}
          >
            Log out
          </Button>
        )}

        {!hideCopyToClipboard && !countdown.isFinished && oneTimeCode.data && (
          <Button
            variant="secondary"
            data-cy="copy-to-clipboard-button"
            className="mt-24"
            loading={copyToClipboard.isPending}
            onClick={() => copyToClipboard.mutate(oneTimeCode.data)}
          >
            {copyToClipboard.isSuccess ? 'Copied!' : 'Copy to clipboard'}
          </Button>
        )}
      </div>
    </div>
  );
};
