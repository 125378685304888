/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryKey } from '@tanstack/react-query';

import { ClientInformation } from '../endpoints/cloudApi.types';
import { SubscriptionManagementType } from '../endpoints/storeApi.types';

type QueryKeys = {
  [K: string]:
    | QueryKey
    | QueryKeys
    | ((...args: any[]) => QueryKey | QueryKeys);
};

// Base keys
const CHECKOUT = 'checkout' as const;
const SUBSCRIPTIONS = 'subscriptions' as const;
const SUBSCRIPTIONS_V2 = 'subscriptionsV2' as const;
const BILLING_INFO = 'billingInfo' as const;
const DEVICES = 'devices' as const;
const PASSCODE_RESET = 'passcodeReset' as const;
const EMAIL = 'email' as const;
const PRODUCT = 'product' as const;
const COUNTRY = 'country' as const;
const INTEGRATIONS = 'integrations' as const;
const SETTINGS = 'settings' as const;
const ONE_TIME_CODE = 'one-time-code' as const;
const DEV = 'dev' as const;
const INVITATIONS = 'invitations' as const;
const FEEDBACK = 'feedback' as const;
const FLEET = 'fleet' as const;
const ENTERPRISE_ENROLLMENT = 'enterpriseEnrollment' as const;
const USER = 'user' as const;
const CONSENT = 'consent' as const;
const ENTERPRISE_ADMIN = 'enterpriseAdmin' as const;
// const FEATURE_FLAGS = 'featureFlags' as const;

export const queryKeys = {
  checkoutBaseKey: [CHECKOUT], // used for targeting all checkout queries
  checkout: {
    allowed: [CHECKOUT, 'allowed'],
    setup: (checkoutType: string) => [CHECKOUT, 'setup', checkoutType] as const,
    details: (checkoutType: string, id = '') =>
      [CHECKOUT, 'details', checkoutType, id] as const,
    tax: [CHECKOUT, 'tax'],
    currency: [CHECKOUT, 'currency'],
    checkoutCart: [CHECKOUT, 'checkoutCart'],
    confirmPayment: [CHECKOUT, 'confirmPayment'],
    country: [CHECKOUT, 'country'],
    withoutCard: [CHECKOUT, 'withoutCard'],
  },
  country: {
    detected: [COUNTRY, 'detected'],
  },
  subscriptionBaseKey: [SUBSCRIPTIONS], // used for targeting all subscription queries
  subscriptions: {
    all: [SUBSCRIPTIONS, 'all'],
    cancel: [SUBSCRIPTIONS, 'cancel'],
    reactivate: [SUBSCRIPTIONS, 'reactivate'],
    createSetupIntent: [SUBSCRIPTIONS, 'createSetupIntent'],
    verifySetupIntent: [SUBSCRIPTIONS, 'verifySetupIntent'],
    deprecated: {
      cancel: [SUBSCRIPTIONS, 'cancel'],
    },
    members: (subscriptionId: string) =>
      [SUBSCRIPTIONS, subscriptionId, 'members'] as const,
    createSeatForMember: (memberId: string) =>
      [SUBSCRIPTIONS, 'createSeatForMember', memberId] as const,
    removeSeatForMember: (memberId: string) =>
      [SUBSCRIPTIONS, 'removeSeatForMember', memberId] as const,
    updateMemberRole: [SUBSCRIPTIONS, 'updateMemberRole'],
    v2: {
      getSeatInvitation: (id: string) =>
        [SUBSCRIPTIONS_V2, 'getSeatInvitation', id] as const,
      updateSubscriptionManagementTypeAllowed: (
        managementType: SubscriptionManagementType
      ) =>
        [
          SUBSCRIPTIONS_V2,
          'updateSubscriptionManagementTypeAllowed',
          managementType,
        ] as const,
    },
  },
  invitations: {
    all: [INVITATIONS, 'all'],
    byId: (id: string) => [INVITATIONS, 'byId', id] as const,
    create: [INVITATIONS, 'create'],
    delete: [INVITATIONS, 'delete'],
  },
  billingInfo: {
    details: [BILLING_INFO, 'details'],
    update: [BILLING_INFO, 'update'],
  },
  devices: {
    all: [DEVICES, 'all'],
    hasEverPairedDevice: [DEVICES, 'hasEverPairedDevice'],
    pollBaseKey: [DEVICES, 'poll'],
    poll: (
      oneTimeCode: string | null | undefined = null
      // originalDevices: Device[] | null
    ) => [DEVICES, 'poll', oneTimeCode] as const,
    activationStatusBaseKey: [DEVICES, 'activationStatus'], // used for targeting all device activation status queries
    activationStatus: (device?: ClientInformation | null) =>
      [DEVICES, 'activationStatus', device] as const,
    delete: [DEVICES, 'delete'],
  },
  passcodeReset: {
    requests: [PASSCODE_RESET, 'requests'],
    approve: [PASSCODE_RESET, 'approve'],
    deny: [PASSCODE_RESET, 'deny'],
  },
  oneTimeCode: {
    get: [ONE_TIME_CODE, 'all'],
  },
  product: (sku: string) => [PRODUCT, sku],
  email: {
    changeWithTokeBaseKey: [EMAIL, 'changeWithToken'], // used for targeting all email change with token queries
    changeWithToken: (urlToken: string) =>
      [EMAIL, 'changeWithToken', urlToken] as const,
    changeStatus: [EMAIL, 'changeStatus'],
    verified: ['email-verified'], // Not sharing base key as it is used for syncing email verification status
  },
  integrations: {
    providers: [INTEGRATIONS, 'providers'],
    providerAuthURI: (providerPath: string) =>
      [INTEGRATIONS, 'providerAuthURI', providerPath] as const,
    all: [INTEGRATIONS, 'all'],
    termsOfServiceStatus: [INTEGRATIONS, 'termsOfServiceStatus'],
  },
  settings: {
    accountInfo: [SETTINGS, 'accountInfo'],
    paymentHistory: [SETTINGS, 'paymentHistory'],
  },
  dev: {
    stripeTestClock: [DEV, 'stripeTestClock'],
  },
  featureFlags: {
    // Keep this key for future feature flags
  },
  feedback: {
    value: (targetId: string) => [FEEDBACK, targetId, 'value'] as const,
    comment: (targetId: string) => [FEEDBACK, targetId, 'comment'] as const,
    addComment: (targetId: string) =>
      [FEEDBACK, targetId, 'addComment'] as const,
  },
  fleet: {
    members: [FLEET, 'members'],
    orgInfo: [FLEET, 'orgInfo'],
  },
  enterpriseAdmin: {
    roles: [ENTERPRISE_ADMIN, 'roles'],
  },
  enterpriseEnrollment: {
    status: [ENTERPRISE_ENROLLMENT, 'status'],
    init: [ENTERPRISE_ENROLLMENT, 'init'],
    connection: [ENTERPRISE_ENROLLMENT, 'connection'],
    verify: [ENTERPRISE_ENROLLMENT, 'verify'],
    cancel: [ENTERPRISE_ENROLLMENT, 'cancel'],
    finalize: [ENTERPRISE_ENROLLMENT, 'finalize'],
  },
  user: {
    login: [USER, 'login'],
    createdAt: [USER, 'createdAt'],
    isFleetUser: [USER, 'isFleetUser'],
    claims: [USER, 'claims'],
    linkAccounts: [USER, 'linkAccounts'],
    startLinkAccounts: [USER, 'startLinkAccounts'],
    showSetup: [USER, 'showSetup'],
    profileMetadata: [USER, 'profileMetadata'],
  },
  consent: {
    details: [CONSENT, 'details'],
  },
} as const satisfies QueryKeys;
