import { EnterpriseEnrollmentStatus } from 'src/api/endpoints/cloudEnterpriseEnrollmentApi.types';

interface EnterpriseEnrollmentState {
  hasInitiatedEnrollment: boolean;
  hasVerifiedDomain: boolean;
  hasConfiguredSaml: boolean;
  hasFinalized: boolean;
}
export function getEnrollmentState(
  data?: EnterpriseEnrollmentStatus | null
): EnterpriseEnrollmentState {
  return {
    hasInitiatedEnrollment: !!(
      data?.connectionId &&
      data?.displayName &&
      data?.primaryDomain
    ),
    hasVerifiedDomain: data?.domainVerification === 'OK',
    hasConfiguredSaml: !!(data?.signInUrl && data?.base64Cert),
    hasFinalized: !!(data?.enrollment === 'FINALIZED'),
  };
}
