import { Typography, Spinner as ArkSpinner } from '@remarkable/ark-web';

import { VerifyEmailChangeContent } from 'src/apps/verifyNewEmail/app/components/VerifyEmailChangeContent';
import { SomethingWentWrong, Spinner } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { useUrlSearchParams } from 'src/hooks/useUrlSearchParams';

import { useVerifyEmail } from './useVerifyEmail';

export const VerifyEmailChange = () => {
  const {
    emailChangeData,
    cancelEmailChange,
    confirmEmailChange,
    confirmEmailChangeIsLoading,
    isLoading,
    isError,
  } = useVerifyEmail(useUrlSearchParams()[0]?.['token'] || '');

  if (confirmEmailChangeIsLoading) {
    return (
      <div className="m-auto flex grow flex-col items-center justify-center">
        <ArkSpinner size="large" className="" />
        <Typography variant="heading-subtle-xl" className="mt-20">
          Changing your email address. Use your new email to sign in.
        </Typography>
      </div>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (!emailChangeData) {
    return <SomethingWentWrong />;
  }

  return (
    <AppPage.Container>
      <AppPage.Header
        title="Change email address"
        subtitle="Please confirm your new email address."
      />
      <AppPage.Content className="flex items-center justify-center">
        <VerifyEmailChangeContent
          emailChangeInfo={emailChangeData}
          cancelEmailChange={cancelEmailChange}
          confirmEmailChange={confirmEmailChange}
          hasError={isError}
          confirmEmailChangeIsLoading={confirmEmailChangeIsLoading}
        />
      </AppPage.Content>
    </AppPage.Container>
  );
};
