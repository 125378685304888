import { Spinner } from 'phosphor-react';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useActivePaperTablets } from 'src/api/queries';
import { Step, Stepper } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Offer } from 'src/components/Offers/Offer';
import { URLS } from 'src/utils/urls/urls';

export const SubscriptionOfferLandingPage = () => {
  const activePaperTablets = useActivePaperTablets();

  if (activePaperTablets.isPending) return <Spinner />;

  const title =
    activePaperTablets.data && activePaperTablets.data.length > 1
      ? 'Sync and transfer files'
      : 'Setting up';

  return (
    <AppPage.Container className="max-w-l2xl">
      <AppPage.Header title={title} />
      <Stepper className="mx-auto max-w-lg pb-32 pt-0 lm:pb-96">
        <Step checked to={URLS.STORE_SUBSCRIPTION_OFFER_LANDING}>
          Pair your device
        </Step>
        <Step disabled to={URLS.STORE_SUBSCRIPTION_OFFER_CHECKOUT}>
          Activate Connect
        </Step>
        <Step disabled to={URLS.STORE_SUBSCRIPTION_OFFER_CONFIRMATION}>
          Setup complete
        </Step>
      </Stepper>

      <AppPage.Content className="max-w-l2xl">
        <Offer
          checkoutType="subscriptionOffer"
          componentLocation={ComponentLocations.CHECKOUT.LANDING}
          expirationDate={null}
        />
      </AppPage.Content>
    </AppPage.Container>
  );
};
