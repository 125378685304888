import React from 'react';

import { useNavigate } from '@tanstack/react-router';
import { ArrowRight } from 'phosphor-react';

import { Button, NotificationBox } from 'src/components';
import { URLS } from 'src/utils/urls/urls';

interface VerifyEmailChangeErrorProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
}

export const VerifyEmailChangeError = (props: VerifyEmailChangeErrorProps) => {
  const { title, children } = props;

  const navigate = useNavigate();

  return (
    <div
      className="-mt-24 flex flex-col items-center gap-24 text-center"
      data-cy="expired-email-change"
    >
      <NotificationBox
        variant="warning"
        title={title}
        className="w-full max-w-ll"
      >
        {children}
      </NotificationBox>
      <Button
        as="a"
        to={URLS.ACCOUNT}
        variant="primary"
        onClick={(e) => {
          e.preventDefault();
          void navigate({ to: '/account' });
        }}
      >
        <span>Account settings</span>
        <ArrowRight color="#fff" />
      </Button>
    </div>
  );
};
