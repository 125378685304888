import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute } from '@tanstack/react-router';

import { Spinner } from 'src/components';

export const Route = createFileRoute('/(public)/logout')({
  component() {
    const auth = useAuth0();

    void auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

    return <Spinner />;
  },
});
