import { ChangeEventHandler, HTMLProps } from 'react';

import { Label } from '@remarkable/ark-web';
import { CaretDown } from 'phosphor-react';
import { useFormContext } from 'react-hook-form';

import { COUNTRIES, CountryData } from 'src/utils/countryList';
import { useFlagImage } from 'src/utils/useFlagImage';

import { cn } from '../utils/classNamesHelper';

interface CountryPickerProps {
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  isEditable?: boolean;
  name?: string;
  countries?: Array<CountryData>;
  disabled?: boolean;
}

export const CountryPicker = ({
  className = '',
  onChange,
  isEditable = true,
  name = 'country',
  countries = COUNTRIES,
  disabled = false,
  ...rest
}: CountryPickerProps & Omit<HTMLProps<HTMLDivElement>, 'onChange'>) => {
  const { register, watch } = useFormContext();
  const value = watch(name) as string;
  const displayValue = countries.find((c) => c.value === value)?.label;
  const flagImage = useFlagImage(value);

  return (
    <div className={'w-full ' + className} {...rest}>
      <Label htmlFor="country">Country</Label>
      {isEditable ? (
        <div className={cn('relative flex items-center rounded bg-white')}>
          {value ? (
            <img
              className="bg-grayscale-gray-50 pointer-events-none absolute ml-12 h-32 w-32 rounded-full"
              src={flagImage.data}
            />
          ) : (
            <div className="bg-grayscale-gray-50 absolute ml-12 h-32 w-32 rounded-full" />
          )}

          <select
            className={cn(
              'h-48 w-full cursor-pointer appearance-none rounded border border-neutral-light-8 bg-none p-12 pl-56',
              {
                'text-gray-500': !value,
                'cursor-wait': disabled,
              }
            )}
            defaultValue="Select country"
            {...register(name, {
              onChange,
            })}
            disabled={disabled}
          >
            <option value="" disabled hidden>
              Select country
            </option>
            {countries
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </select>
          <CaretDown
            size={32}
            className="pointer-events-none absolute right-12 top-0 h-full text-pen-blue"
          />
        </div>
      ) : (
        <p className="min-h-[16px]">{displayValue ?? rest.value ?? '-'}</p>
      )}
    </div>
  );
};
