/*eslint-env node*/

/* eslint-disable no-undef */
import { CacheLocation } from '@auth0/auth0-react';

// used for syncing config with sessionStorage in development
export const featureToggleKey = 'featureToggles';
export const featureTogglePrefix = 'Enable';

export const defaultConfig = {
  StoreAPIURL: import.meta.env.PUBLIC_RM_STORE_API_URL,
  CloudApiUrl: import.meta.env.PUBLIC_CLOUD_API_URL,
  CloudEnterpriseApiUrl: import.meta.env.PUBLIC_CLOUD_ENTERPRISE_API_URL,
  Auth0Domain: import.meta.env.PUBLIC_AUTH0_DOMAIN,
  Auth0ClientID: import.meta.env.PUBLIC_AUTH0_CLIENT_ID,
  Auth0Audience: import.meta.env.PUBLIC_AUTH0_AUDIENCE,
  Auth0CacheLocation: (import.meta.env.PUBLIC_AUTH0_CACHE_LOCATION ===
  'localstorage'
    ? 'localstorage'
    : 'memory') as CacheLocation,
  /** @deprecated use `IS_DEVELOPMENT` or `IS_PRODUCTION` instead */
  Environment: import.meta.env.PUBLIC_ENVIRONMENT,
  DownloadsURL: import.meta.env.PUBLIC_DOWNLOADS_URL,
  Release: import.meta.env.PUBLIC_RELEASE,
  EnableReactQueryDevtools:
    import.meta.env.PUBLIC_REACT_QUERY_DEVTOOLS === 'true',
  EnableUnlinkSocialAccount:
    import.meta.env.PUBLIC_ENABLE_UNLINK_SOCIAL_ACCOUNTS === 'true',
  EnableInviteWithRole:
    import.meta.env.PUBLIC_ENABLE_INVITE_WITH_ROLE === 'true',
  TectonicTokenKey: 'https://auth.remarkable.com/tectonic',
  RmStoreUrl: import.meta.env.PUBLIC_RM_STORE_URL ?? '',
  TrackingAPI: import.meta.env.PUBLIC_MYRM_TRACKING_API,
  TrackingCookieDomain: import.meta.env.PUBLIC_MYRM_TRACKING_COOKIE_DOMAIN,
  ScreenshareSignalingUrl: import.meta.env.PUBLIC_SCREENSHARE_SIGNALING_URL,
  EnableUnoccupiedSeats:
    import.meta.env.PUBLIC_ENABLE_UNOCCUPIED_SEATS === 'true',
  EnableSeatsBulkActions: false,
  EnableLocalMyFilesApp:
    import.meta.env.PUBLIC_ENABLE_LOCAL_MY_FILES_APP === 'true',
  EnableSwitchCollectionMethod:
    import.meta.env.PUBLIC_ENABLE_SWITCH_COLLECTION_METHOD === 'true',
};

export const CONFIG = createConfig(defaultConfig);

export type ConfigKey = keyof typeof CONFIG;

export const IS_PRODUCTION =
  import.meta.env.PUBLIC_ENVIRONMENT === 'production' ||
  import.meta.env.PUBLIC_ENVIRONMENT === 'staging';
export const IS_DEVELOPMENT = !IS_PRODUCTION;

export type FeatureToggleKey = {
  [key in ConfigKey]: key extends `${typeof featureTogglePrefix}${infer Feature}`
    ? `${typeof featureTogglePrefix}${Feature}`
    : never;
}[ConfigKey];

function createConfig<T extends Record<string, string | boolean | undefined>>(
  config: T
) {
  const isDevelopment = import.meta.env.PUBLIC_ENVIRONMENT === 'development';
  if (!isDevelopment) {
    // Don't store feature toggles if it is not development
    return config;
  }

  const storage = sessionStorage.getItem(featureToggleKey);

  if (!storage) {
    // Make sure we only store feature toggles
    sessionStorage.setItem(
      featureToggleKey,
      JSON.stringify(
        Object.fromEntries(
          Object.entries(config).filter(([key]) => {
            return key.startsWith(featureTogglePrefix);
          })
        )
      )
    );
  }

  // This proxy will sync feature toggles with sessionStorage
  // in development mode. This allows us to enable/disable features in the
  // browser without having to restart the app.
  return new Proxy(config, {
    get(target, name) {
      let value = target[name as string];

      if (isDevelopment && (name as string).startsWith(featureTogglePrefix)) {
        const storage = sessionStorage.getItem(featureToggleKey);

        if (storage) {
          const env = JSON.parse(storage) as T;
          const sessionValue = env[name as keyof T];

          if (typeof sessionValue !== 'undefined' && sessionValue !== null) {
            value = sessionValue;
          }
        }
      }

      // convert string to boolean
      if (value === 'true') {
        value = true;
      }
      if (value === 'false') {
        value = false;
      }

      return value ?? '';
    },
  });
}
