import { Typography } from '@remarkable/ark-web';
import { ArrowRight } from 'phosphor-react';

import { useCheckoutAllowed } from 'src/api/queries';
import { Button, CardContent, Spinner } from 'src/components';
import { ExpirationText } from 'src/components/Offers/ExpirationText';
import { UnreachableCodeError } from 'src/utils/unreachableCode';
import { URLS } from 'src/utils/urls/urls';

export const ConnectOfferInfo = ({
  offerType,
}: {
  offerType: 'retailOffer' | 'subscriptionOffer' | 'connectOffer' | 'dmOffer';
}) => {
  const checkoutAllowed = useCheckoutAllowed();
  if (checkoutAllowed.isPending) {
    return <Spinner />;
  }
  switch (offerType) {
    case 'retailOffer':
      return (
        <div data-cy="retail-offer-info">
          <Typography variant="heading-subtle-2xl" as="h2">
            Activate your included 12-month Connect Plan
          </Typography>
          <CardContent dataCy="subscription-no-plan" className="pb-24">
            <Typography variant="body-md-regular">
              Enjoy seamless note-taking with unlimited cloud storage and sync,
              and up to three years added device protection.
            </Typography>
          </CardContent>
          <Button variant="primary" as="a" to={URLS.STORE_RETAIL_OFFER_LANDING}>
            <span>Activate Connect</span>
            <ArrowRight size={24} />
          </Button>
        </div>
      );

    case 'subscriptionOffer':
      return (
        <div data-cy="subscription-offer-info">
          <Typography variant="heading-subtle-2xl" as="h2">
            Activate your 1-year free Connect subscription
          </Typography>
          <CardContent dataCy="subscription-no-plan" className="pb-24">
            <Typography variant="body-md-regular">
              Enjoy seamless note-taking with unlimited cloud storage and sync,
              and up to three years added device protection.
            </Typography>
          </CardContent>
          <Button
            variant="primary"
            as="a"
            to={URLS.STORE_SUBSCRIPTION_OFFER_LANDING}
          >
            <span>Activate Connect</span>
            <ArrowRight size={24} />
          </Button>
        </div>
      );

    case 'connectOffer':
      return (
        <div data-cy="subscription-offer-info">
          {checkoutAllowed.data?.connectOffer.allowed && (
            <div className="mb-16">
              <ExpirationText
                expirationDate={checkoutAllowed.data.connectOffer.expiresAt}
              />
            </div>
          )}

          <Typography variant="heading-subtle-2xl" as="h2">
            Activate your 100 days free Connect subscription
          </Typography>
          <CardContent dataCy="subscription-no-plan" className="pb-24">
            <Typography variant="body-md-regular">
              Enjoy seamless note-taking with unlimited cloud storage and sync,
              and up to three years added device protection.
            </Typography>
          </CardContent>
          <Button
            variant="primary"
            as="a"
            to={URLS.STORE_CONNECT_OFFER_LANDING}
          >
            <span>Activate Connect</span>
            <ArrowRight size={24} />
          </Button>
        </div>
      );
    case 'dmOffer':
      return (
        <div data-cy="subscription-offer-info">
          <Typography variant="heading-subtle-2xl" as="h2">
            Activate your 30 days free Connect subscription
          </Typography>
          <CardContent dataCy="subscription-no-plan" className="pb-24">
            <Typography variant="body-md-regular">
              Enjoy seamless note-taking with unlimited cloud storage and sync,
              and up to three years added device protection.
            </Typography>
          </CardContent>
          <Button variant="primary" as="a" to={URLS.STORE_DM_OFFER_LANDING}>
            <span>Activate Connect</span>
            <ArrowRight size={24} />
          </Button>
        </div>
      );
    default:
      throw new UnreachableCodeError(offerType);
  }
};
