import { ReactNode } from 'react';

import { Typography } from '@remarkable/ark-web';

import { cn } from 'src/utils/classNamesHelper';

import { ImageSource, ImageWithFallback } from './ImageWithFallback';

interface DisplayCardProps {
  className?: string;
  imgClassName?: string;
  imgSources: ImageSource[];
  imgOverlay?: ReactNode;
  imgAlt: string;
  title?: string;
  children: ReactNode;
}

export const DisplayCard = ({
  className,
  imgClassName,
  imgOverlay,
  children,
  imgAlt,
  imgSources,
  title,
}: DisplayCardProps) => {
  return (
    <div
      className={cn(
        'overflow-hidden rounded bg-neutral-light-1 shadow-md',
        className
      )}
    >
      <div className="relative">
        <div className="h-full w-full bg-neutral-light-5">
          <ImageWithFallback
            className="h-full w-full"
            imgClassName={cn(
              'object-cover max-h-[160px] opacity-70 w-full',
              imgClassName
            )}
            sources={imgSources}
            alt={imgAlt}
          />
        </div>
        {imgOverlay && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            {imgOverlay}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-16 p-24 lm:p-32">
        {title && (
          <Typography as="h3" variant="heading-md">
            {title}
          </Typography>
        )}

        {children}
      </div>
    </div>
  );
};
