import { Typography } from '@remarkable/ark-web';
import { Timer } from 'phosphor-react';

import { formatDate } from 'src/utils/formatDate';

const getExpirationText = (expirationDateIsoString: string | null) => {
  if (!expirationDateIsoString) {
    return null;
  }

  const expirationDate = new Date(expirationDateIsoString);
  const currentDate = new Date();
  const diffTime = expirationDate.getTime() - currentDate.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays >= 30) {
    return `Free trial available for ${diffDays} days`;
  } else if (diffDays > 0) {
    return `Free trial available until ${formatDate(expirationDate)}`;
  } else {
    return null;
  }
};

export const ExpirationText = ({
  expirationDate,
}: {
  expirationDate: string | null;
}) => {
  const expirationText = getExpirationText(expirationDate);

  if (!expirationText) {
    return null;
  }

  return (
    <span className="flex flex-row items-center">
      <Timer size={24} className="mr-8 inline-block shrink-0 text-pen-blue" />
      <Typography
        variant="interface-sm-caps-medium"
        className="inline-block text-pen-blue"
      >
        {getExpirationText(expirationDate)}
      </Typography>
    </span>
  );
};
