import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import { FaqSection, Feedback, Link, Spinner } from 'src/components';
import { FAQ } from 'src/constants/FAQ';
import { URLS } from 'src/utils/urls/urls';

import { MembersTable } from '../components/MembersTable';
import { SubscriptionSeatsInfo } from '../components/SubscriptionSeatsInfo';
import { useMembersList } from '../utils/useMembersList';

export const MembersPage = () => {
  const members = useMembersList();

  if (members.isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-24 lm:gap-32">
      <SubscriptionSeatsInfo />

      <MembersTable />

      {members.data.length <= 1 && (
        <div className="flex flex-col items-center justify-center bg-neutral-light-3 p-24">
          <Typography variant="body-md-bold" className="font-medium">
            Invite team members to get started
          </Typography>

          <Typography variant="body-sm-regular">
            Need help? Read our{' '}
            <Link to={URLS.RM_COM_CONNECT_FOR_BUSINESS_GET_STARTED} inline>
              quick start guide
            </Link>
          </Typography>
        </div>
      )}

      <FaqSection
        className="my-64"
        componentLocation={ComponentLocations.SUBSCRIPTION.MEMBERS_PAGE}
        sections={[
          FAQ.connectForBusiness.whatHappensToTeamMembersFiles(),
          FAQ.connectForBusiness.canIAccessTeamMembersFiles(),
          FAQ.connectForBusiness.whatHappensIfIRemoveAMember(),
          FAQ.connectForBusiness.whatDoesActiveSeatMean(),
          FAQ.connectForBusiness.howDoesBillingWork(),
          FAQ.connectForBusiness.asAnOwnerIDontNeedAccessToSubscriptionFeatures(),
          FAQ.connectForBusiness.whatIsTheDifferenceBetweenTheUserManagerAndOwnerRoles(),
        ]}
      />

      <Feedback
        feedbackTargetId="c4b-members-page-for-owner-v1"
        title="Did you find what you were looking for?"
      />
    </div>
  );
};
