import { useEffect } from 'react';

import { LockSimple, ShareFat } from '@phosphor-icons/react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { RM2 } from '@remarkable/ark-icons';
import { Typography } from '@remarkable/ark-web';
import clsx from 'clsx';
import {
  ArrowRight,
  Cardholder,
  Check,
  Link as LinkIcon,
  Users,
} from 'phosphor-react';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCountry, useProductPricesOpen } from 'src/api/queries';
import inviteModalPreviewPng from 'src/assets/img/c4b-invite-modal-preview.png';
import inviteModalPreviewWebp from 'src/assets/img/c4b-invite-modal-preview.webp';
import memberListPreviewPng from 'src/assets/img/c4b-member-list-preview.png';
import memberListPreviewWebp from 'src/assets/img/c4b-member-list-preview.webp';
import tagBackdropJpg from 'src/assets/img/c4b-tag-backdrop.jpg';
import tagBackdropWebp from 'src/assets/img/c4b-tag-backdrop.webp';
import {
  BetaTag,
  Button,
  Divider,
  FaqSection,
  Feedback,
  ImageWithFallback,
  Link,
  Spinner,
} from 'src/components';
import { DisplayCard } from 'src/components/DisplayCard';
import { FAQ } from 'src/constants/FAQ';
import { formatAmountBrowserDefaultLocale } from 'src/utils/productUtils';
import { SKUs } from 'src/utils/skus';
import { URLS } from 'src/utils/urls/urls';
import { useCopyToClipboard } from 'src/utils/useCopyToClipboard';
import { valueProps } from 'src/utils/valueProps';

export const ConnectForBusinessOpenValueProp = () => {
  const copyToClipboard = useCopyToClipboard();

  const connectProductPrices = useProductPricesOpen(SKUs.connectMonthly);

  const country = useCountry();

  useEffect(() => {
    // temporary fix for the scroll position not being reset when navigating to this page
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (copyToClipboard.isSuccess) {
      setTimeout(() => {
        copyToClipboard.reset();
      }, 2000);
    }
  }, [copyToClipboard.isSuccess]);

  if (connectProductPrices.isPending || country.isPending) {
    return <Spinner />;
  }

  const determinePrice = () => {
    const defaultPrice = { amount: 2900, currency: 'USD', includes_tax: false };

    if (
      !connectProductPrices.isError &&
      connectProductPrices.data &&
      !country.isError &&
      country.data
    ) {
      const matchedPrice = connectProductPrices.data.find(
        (price) => price.currency === country.data.currency
      );
      return matchedPrice ? { ...matchedPrice } : defaultPrice;
    }

    return defaultPrice;
  };

  const price = determinePrice();

  return (
    <div
      className="flex w-full animate-fade-in flex-col items-center justify-center"
      data-cy="enroll-to-seats-page"
    >
      <div className="relative w-full animate-fade-in-down bg-gradient-to-b from-neutral-light-1 from-5% to-neutral-light-5 to-90%">
        <div className="m-auto flex w-full max-w-l2xl flex-col items-center gap-24 overflow-y-clip px-16 ls:px-32 l2xl:flex-row l2xl:items-end">
          <div className="m-auto flex flex-col items-center justify-center gap-32 pb-16 pt-64 text-center ll:pb-24 l2xl:items-start l2xl:justify-start l2xl:py-96 l2xl:text-left">
            <p className="heading-subtle-xl text-center l2xl:text-left">
              Connect for business <BetaTag className="ml-16" />
            </p>

            <h1 className="heading-md max-w-ll ll:heading-lg l2xl:heading-xl">
              Manage your company&apos;s subscriptions
            </h1>

            <div className="grid gap-16 lm:grid-cols-2 lm:text-left l2xl:justify-start">
              <Typography
                variant="body-sm-regular"
                className="whitespace-nowrap"
              >
                <Cardholder className="inline text-pen-blue" size={24} />{' '}
                <span>Centralized billing</span>
              </Typography>

              <Typography
                variant="body-sm-regular"
                className="whitespace-nowrap"
              >
                <LockSimple className="inline text-pen-blue" size={24} />{' '}
                <span>Enterprise single sign-on (coming soon)</span>
              </Typography>

              <Typography
                variant="body-sm-regular"
                className="whitespace-nowrap"
              >
                <Users className="inline text-pen-blue" size={24} />{' '}
                <span>Subscription administration</span>
              </Typography>

              <Typography
                variant="body-sm-regular"
                className="whitespace-nowrap"
              >
                <RM2 className="inline text-pen-blue" size={24} />{' '}
                <span>Device management (coming soon)</span>
              </Typography>
            </div>

            <div>
              <Typography variant="body-md-regular" className="my-0 ">
                Want Connect for business where you work? Subscribe, or let your
                company know by sharing this page.
              </Typography>
            </div>

            <div className="flex flex-col items-center gap-16 sm:flex-row">
              <Button
                as="a"
                size="medium"
                variant="primary"
                to={URLS.STORE_CONNECT_FOR_BUSINESS_CHECKOUT}
                data-cy="enroll-to-seats-button"
                analytics={{
                  action: 'open connect for business checkout form',
                  location:
                    ComponentLocations.CONNECT_FOR_BUSINESS
                      .VALUE_PROP_PUBLIC_PAGE,
                  text: 'Start business subscription',
                }}
              >
                <span>Start business subscription</span> <ArrowRight />
              </Button>

              <Popover
                onOpenChange={(isOpen) => {
                  if (isOpen) {
                    tracker.trackEvent(
                      new ButtonClicked({
                        component_location:
                          ComponentLocations.CONNECT_FOR_BUSINESS
                            .VALUE_PROP_PAGE,
                        text: 'Share',
                        action: 'open share popover',
                      })
                    );
                  }
                }}
              >
                <PopoverTrigger asChild>
                  <Button
                    variant="secondary"
                    className="w-full sm:w-auto"
                    size="medium"
                  >
                    <span>Share</span>
                    <ShareFat />
                  </Button>
                </PopoverTrigger>

                <PopoverContent
                  align="start"
                  sideOffset={-40}
                  collisionPadding={24}
                  className="z-20 ml-[-1px] rounded bg-neutral-dark-5 px-20 py-16 shadow-2xl transition-all"
                  onOpenAutoFocus={(event) => event.preventDefault()}
                >
                  <Typography
                    variant="interface-lg-semibold"
                    className="my-0 text-left text-neutral-light-1"
                  >
                    Share
                  </Typography>

                  <div className="my-16 rounded bg-neutral-light-8/10 px-8 py-2">
                    <Typography
                      variant="interface-sm-regular"
                      className="text-neutral-light-1"
                    >
                      {window.location.origin +
                        URLS.CONNECT_FOR_BUSINESS_PUBLIC}
                    </Typography>
                  </div>

                  <button
                    className="relative flex items-center justify-center rounded-full bg-neutral-light-2 px-12 py-4 align-middle text-14 font-medium outline-none transition-all hover:bg-neutral-light-5 focus:bg-pen-blue-light [&>span]:mx-8"
                    onClick={() => {
                      copyToClipboard.mutate(
                        window.location.origin +
                          URLS.CONNECT_FOR_BUSINESS_PUBLIC
                      );

                      tracker.trackEvent(
                        new ButtonClicked({
                          component_location:
                            ComponentLocations.CONNECT_FOR_BUSINESS
                              .VALUE_PROP_PAGE_SHARE_POPOVER,
                          text: 'Copy link',
                          action: 'copy connect for business public link',
                        })
                      );
                    }}
                  >
                    <LinkIcon
                      className={clsx({
                        invisible: copyToClipboard.isSuccess,
                      })}
                    />
                    <span
                      className={clsx({
                        invisible: copyToClipboard.isSuccess,
                      })}
                    >
                      Copy link
                    </span>

                    <div
                      className={clsx(
                        'absolute left-0 w-full items-center justify-center',
                        {
                          hidden: !copyToClipboard.isSuccess,
                          flex: copyToClipboard.isSuccess,
                        }
                      )}
                    >
                      <Check className="mr-4 shrink-0" /> <span>Copied!</span>
                    </div>
                  </button>
                </PopoverContent>
              </Popover>
            </div>

            <div>
              <Typography
                variant="interface-xs-regular"
                className="text-interface-text-muted my-0 text-pretty leading-4"
              >
                New users invited to your subscription get a 100-day free{' '}
                <Link inline to={URLS.RM_COM_STORE_CONNECT}>
                  Connect
                </Link>{' '}
                trial, then{' '}
                {price
                  ? formatAmountBrowserDefaultLocale(
                      price.amount,
                      price.currency
                    )
                  : ''}
                /mo. Cancel anytime.
              </Typography>
            </div>
          </div>

          <div className="relative aspect-[9/6] w-full max-w-ll lm:aspect-[9/4] l2xl:aspect-[9/5] l2xl:w-[85%]">
            <div
              onClick={() => {
                tracker.trackEvent(
                  new ButtonClicked({
                    component_location:
                      ComponentLocations.CONNECT_FOR_BUSINESS.VALUE_PROP_PAGE,
                    action: 'click member list preview image',
                  })
                );
              }}
              className="absolute left-[-28%] top-[20%] w-[110%] animate-fade-in-up opacity-0 shadow-image animation-duration-1000 lm:left-40 lm:w-[80%]"
            >
              <div className="absolute bottom-0 left-0 h-2/3 w-full bg-gradient-to-b from-transparent to-neutral-light-5/60" />
              <ImageWithFallback
                imgClassName="w-full"
                sources={[
                  { url: memberListPreviewPng, type: 'image/png' },
                  { url: memberListPreviewWebp, type: 'image/webp' },
                ]}
                alt="Member list preview"
              />
            </div>
            <ImageWithFallback
              onClick={() => {
                tracker.trackEvent(
                  new ButtonClicked({
                    component_location:
                      ComponentLocations.CONNECT_FOR_BUSINESS.VALUE_PROP_PAGE,
                    action: 'click invite modal preview image',
                  })
                );
              }}
              className="absolute right-0 top-[5%] w-[60%] animate-fade-in-up rounded-[16px] shadow-2xl animation-duration-1000 lm:w-[40%]"
              imgClassName="w-full"
              sources={[
                { url: inviteModalPreviewPng, type: 'image/png' },
                { url: inviteModalPreviewWebp, type: 'image/webp' },
              ]}
              alt="Invite modal preview"
            />
          </div>
        </div>
      </div>

      <div className="mx-auto mt-32 flex max-w-l2xl flex-col gap-24 px-16 ls:px-32">
        <div className="flex flex-col items-center justify-center gap-32 py-24 lm:gap-24 l2xl:py-48">
          <h2 className="heading-sm text-center ls:heading-md">
            Take your work further with Connect
          </h2>

          <Typography variant="body-md-regular" className="text-center">
            Give your team access to mobile and desktop note-taking with
            unlimited cloud storage and sync.
          </Typography>

          <ul className="mb-48 grid grid-cols-2 gap-24 ll:grid-cols-4 ll:gap-48">
            {Object.values(valueProps.connectForBusiness).map((valueProp) => (
              <li
                key={valueProp.id}
                className="w-1fr flex max-w-[200px] flex-col items-center justify-start"
              >
                <valueProp.icon
                  className="text-pen-blue"
                  size={40}
                  weight="light"
                />
                <Typography
                  variant="body-sm-regular"
                  className="mt-12 text-pretty text-center"
                >
                  {valueProp.title}
                </Typography>
              </li>
            ))}
          </ul>

          <div className="w-full rounded  bg-neutral-light-1 p-24 shadow-md">
            <Typography
              variant="interface-md-regular"
              className="flex flex-col flex-wrap items-center justify-center gap-8 text-center leading-6 ls:flex-row"
            >
              <Typography as="span" variant="interface-md-semibold">
                Want your company to manage your subscription?
              </Typography>{' '}
              <span className="lm:whitespace-nowrap">
                Ask your manager to invite you, or share this page.
              </span>
              <Button
                variant="tertiary"
                size="small"
                className="!m-0 w-[100px]"
                loading={copyToClipboard.isPending}
                onClick={() => {
                  copyToClipboard.mutate(
                    window.location.origin + URLS.CONNECT_FOR_BUSINESS_PUBLIC
                  );

                  tracker.trackEvent(
                    new ButtonClicked({
                      component_location:
                        ComponentLocations.CONNECT_FOR_BUSINESS
                          .VALUE_PROP_PUBLIC_PAGE,
                      text: 'Copy',
                      action: 'copy connect for business public link',
                    })
                  );
                }}
              >
                <LinkIcon
                  className={clsx({
                    invisible: copyToClipboard.isSuccess,
                  })}
                />{' '}
                <span
                  className={clsx({
                    invisible: copyToClipboard.isSuccess,
                  })}
                >
                  Copy
                </span>
                <div
                  className={clsx(
                    'absolute left-0 w-full items-center justify-center',
                    {
                      hidden: !copyToClipboard.isSuccess,
                      flex: copyToClipboard.isSuccess,
                    }
                  )}
                >
                  <Check className="mr-4 shrink-0" /> <span>Copied!</span>
                </div>
              </Button>
            </Typography>
          </div>
        </div>

        <Divider title="How it works" className="my-24" />

        <div className="mb-24 grid grid-cols-1 gap-24 lm:grid-cols-2 lxl:grid-cols-3">
          <DisplayCard
            imgSources={[
              {
                url: tagBackdropJpg,
                type: 'image/jpeg',
              },
              { url: tagBackdropWebp, type: 'image/webp' },
            ]}
            imgAlt="Why join the beta?"
            title="Why join the beta?"
            imgOverlay={<BetaTag />}
          >
            <Typography variant="body-md-regular">
              Coming soon, we&apos;ll expand Connect for business with
              top-requested features like device management and enterprise
              single sign-on (SSO).
            </Typography>
            <Typography variant="body-md-regular">
              Join the beta to test new features as they&apos;re released, and
              share your thoughts directly with our team.
            </Typography>
          </DisplayCard>

          <DisplayCard
            imgSources={[
              {
                url: tagBackdropJpg,
                type: 'image/jpeg',
              },
              { url: tagBackdropWebp, type: 'image/webp' },
            ]}
            imgAlt="Centralized billing"
            title="Centralized billing"
            imgOverlay={
              <div className="rounded-full bg-neutral-light-5 p-12">
                <Cardholder size={48} weight="light" />
              </div>
            }
          >
            <Typography variant="body-md-regular">
              Pay for and manage multiple subscriptions from a single account.
              The monthly price is linked to your team&apos;s size and updates
              automatically when you add or remove members.
            </Typography>
          </DisplayCard>

          <DisplayCard
            className="lm:col-span-2 lxl:col-span-1"
            imgSources={[
              {
                url: tagBackdropJpg,
                type: 'image/jpeg',
              },
              { url: tagBackdropWebp, type: 'image/webp' },
            ]}
            imgAlt="Subscription administration"
            title="Subscription administration"
            imgOverlay={
              <div className="rounded-full bg-neutral-light-5 p-12">
                <Users size={48} weight="light" />
              </div>
            }
          >
            <Typography variant="body-md-regular">
              Manage team members with an easy-to-use dashboard.
            </Typography>

            <Typography variant="body-md-regular" className="text-pretty">
              New members can keep or redeem their free Connect trial when
              joining your subscription.
            </Typography>
          </DisplayCard>
        </div>

        <Button
          className="m-auto w-full ls:w-fit"
          as="a"
          variant="primary"
          to={URLS.STORE_CONNECT_FOR_BUSINESS_CHECKOUT}
          data-cy="enroll-to-seats-button"
          analytics={{
            action: 'open connect for business checkout form',
            location:
              ComponentLocations.CONNECT_FOR_BUSINESS
                .VALUE_PROP_PUBLIC_PAGE_BOTTOM,
            text: 'Start business subscription',
          }}
        >
          <span>Start business subscription</span> <ArrowRight />
        </Button>

        <Feedback
          className="mt-32 lm:mt-64"
          feedbackTargetId="c4b-owner-value-prop-public-page-v1"
          title="Did you find the information you needed about Connect for business?"
        />

        <FaqSection
          className="my-64"
          componentLocation={
            ComponentLocations.CONNECT_FOR_BUSINESS.VALUE_PROP_PUBLIC_PAGE
          }
          sections={[
            FAQ.connectForBusiness.whatHappensToTeamMembersFiles(),
            FAQ.connectForBusiness.canIAccessTeamMembersFiles(),
            FAQ.connectForBusiness.whatHappensWith1YearFreeTrial(),
            FAQ.connectForBusiness.willThePriceChangeWhenTheBetaPeriodEnds(),
            FAQ.connectForBusiness.doYouOfferDeviceManagement(),
            FAQ.connectForBusiness.howDoesBillingWork(price),
            FAQ.connectForBusiness.canIManageASubscriptionWithoutPayingForConnectForMyself(),
            FAQ.connectForBusiness.whenWillIBeCharged(),
            FAQ.connectForBusiness.whatPaymentOptionsDoYouOffer(),
          ]}
        />
      </div>
    </div>
  );
};
