import { useAuth0 } from '@auth0/auth0-react';
import { MenuButton } from '@remarkable/ark-web';
import { CheckCircle, Warning } from 'phosphor-react';
import { Toaster } from 'react-hot-toast';

import { Link } from 'src/components';
import { Link2 } from 'src/components/Link2';
import { RemarkableLogo } from 'src/components/RemarkableLogo';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';
import { URLS } from 'src/utils/urls/urls';

import { HeaderNotification } from './HeaderNotification';
import { useSideMenuState } from './components/SideMenu';

interface HeaderProps {
  variant: 'full' | 'minimal';
}

export const Header = (props: HeaderProps) => {
  const { variant } = props;

  const auth = useAuth0();
  const sideMenu = useSideMenuState();
  return (
    <>
      <div
        data-cy="header"
        className="fixed left-0 top-0 z-40 flex h-56 w-full animate-fade-in-down items-center bg-neutral-light-4 px-20 text-black smd:h-64"
      >
        {variant === 'full' && auth.isAuthenticated && (
          <MenuButton
            menuDrawerId={'menu-button'}
            className="float-left p-4"
            menuDrawerOpen={true} // We use our own component for the side menu, so this prop doesn't do anything
            onClick={() => sideMenu.open()}
            data-cy="open-nav-menu"
            aria-label="Open menu"
          />
        )}

        {variant === 'full' ? (
          <Link2
            to="/"
            className="absolute left-1/2 flex h-full -translate-x-1/2 items-center"
          >
            <RemarkableLogo color="black" />
          </Link2>
        ) : (
          <div className="absolute left-1/2 flex h-full -translate-x-1/2 items-center">
            <RemarkableLogo color="black" />
          </div>
        )}

        {variant === 'full' && (
          <div className="absolute right-20 hidden h-full items-center gap-24 lm:flex">
            <Link
              inline
              to={SUPPORT_URL.HOME}
              className="no-underline hover:underline"
            >
              Support
            </Link>
            <Link
              inline
              to={URLS.RM_COM}
              className="no-underline hover:underline"
            >
              remarkable.com
            </Link>
          </div>
        )}
      </div>
      <div className="h-56 smd:h-64" />
      {variant === 'full' && (
        <div className="w-full">
          <HeaderNotification />
        </div>
      )}
      <Toaster
        position="top-right"
        containerStyle={{ top: 72, zIndex: 49 }}
        toastOptions={{
          style: {
            borderRadius: '4px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '12px',
            paddingBottom: '12px',
            alignItems: 'center',
          },
          error: {
            icon: (
              <Warning size={24} className="shrink-0 text-feedback-red-500" />
            ),
          },
          success: {
            icon: (
              <CheckCircle
                size={24}
                className="shrink-0 text-feedback-green-500"
              />
            ),
          },
        }}
      />
    </>
  );
};
