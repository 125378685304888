import { ComponentLocations } from 'src/analytics/trackingTypes';
import { useCheckoutAllowed } from 'src/api/queries';
import { Spinner } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Offer } from 'src/components/Offers/Offer';

export const DmOfferLandingPage = () => {
  const checkoutAllowed = useCheckoutAllowed();
  if (checkoutAllowed.isPending) {
    return <Spinner />;
  }

  if (checkoutAllowed.data?.dmOffer.allowed) {
    return (
      <AppPage.Content className="max-w-l2xl">
        <Offer
          checkoutType="dmOffer"
          componentLocation={ComponentLocations.CHECKOUT.LANDING}
          expirationDate={checkoutAllowed.data.dmOffer.expiresAt}
        />
      </AppPage.Content>
    );
  }
};
