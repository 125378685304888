import { Typography } from '@remarkable/ark-web';

import { ChangeEmailInfo } from 'src/api/endpoints/storeApi.types';
import { VerifyEmailChangeError } from 'src/apps/verifyNewEmail/app/components/VerifyEmailChangeError';
import { isExpired } from 'src/apps/verifyNewEmail/app/utils';
import { Button, NotificationBox } from 'src/components';

interface EmailChangeStatusContentProps {
  emailChangeInfo: ChangeEmailInfo;
  cancelEmailChange: () => void;
  confirmEmailChange: () => void;
  hasError: boolean;
  confirmEmailChangeIsLoading: boolean;
}

export const VerifyEmailChangeUnknownError = () => (
  <VerifyEmailChangeError
    title={<>We couldn&apos;t verify your email address</>}
  >
    <Typography variant="body-md-regular">
      Go to <b>Account settings</b> to try again.
    </Typography>
  </VerifyEmailChangeError>
);

export const VerifyEmailChangeContent = (
  props: EmailChangeStatusContentProps
) => {
  const {
    emailChangeInfo,
    cancelEmailChange,
    confirmEmailChange,
    hasError,
    confirmEmailChangeIsLoading,
  } = props;

  if (hasError) {
    return <VerifyEmailChangeUnknownError />;
  }

  switch (emailChangeInfo.status) {
    case 'pending': {
      if (isExpired(emailChangeInfo)) {
        return (
          <VerifyEmailChangeError title="Your verification link has expired">
            <Typography variant="body-md-regular">
              Go to <b>Account settings</b> to try again.
            </Typography>
          </VerifyEmailChangeError>
        );
      }

      return (
        <div
          className="-mt-32 flex flex-col gap-24 text-center"
          data-cy="pending-email-change"
        >
          <Typography variant="body-md-regular">
            The email linked to this account will change from{' '}
            <strong>{emailChangeInfo.oldEmail}</strong> to{' '}
            <strong>{emailChangeInfo.newEmail}</strong>.
          </Typography>
          <Typography variant="body-md-regular">
            If everything looks correct, select &quot;Change email&quot; below
          </Typography>
          <NotificationBox variant="warning" title="Note">
            It may take up to 8 hours for your new email address to appear on
            your paper tablet and in the apps.
          </NotificationBox>
          <div className="flex w-full flex-col justify-center gap-16 lm:flex-row ">
            <Button
              variant="secondary"
              onClick={cancelEmailChange}
              className="w-full lm:w-fit"
            >
              <span>Cancel</span>
            </Button>
            <Button
              onClick={confirmEmailChange}
              className="w-full lm:w-fit"
              variant="primary"
              loading={confirmEmailChangeIsLoading}
              disabled={confirmEmailChangeIsLoading}
            >
              <span>Change email</span>
            </Button>
          </div>
        </div>
      );
    }
    case 'expired':
      return (
        <VerifyEmailChangeError title="Your verification link has expired">
          <Typography variant="body-md-regular">
            Go to <b>Account settings</b> to try again.
          </Typography>
        </VerifyEmailChangeError>
      );
    case 'cancelled':
      return (
        <VerifyEmailChangeError
          title={<>We won&apos;t change your email address</>}
        >
          <Typography variant="body-md-regular">
            We&apos;ve canceled your request to change your email address, and
            the verification link is no longer active.
          </Typography>
        </VerifyEmailChangeError>
      );
  }

  return <VerifyEmailChangeUnknownError />;
};
