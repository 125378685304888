import { useMemo } from 'react';

import {
  SeatInvitation,
  SubscriptionMember,
} from 'src/api/endpoints/storeApi.types';
import { useStripeSubscription, useSubscriptionMembers } from 'src/api/queries';
import { useInvitations } from 'src/api/queries/invitations';
import { formatRoleNameCapitalized } from 'src/utils/roleNameHelper';
import { useFeatureToggles } from 'src/utils/useFeatureToggles';

import { getSeatStatus } from './getSeatStatus';

type MemberBase = {
  id: string;
  type: 'subscription' | 'invitation';
  title: string;
  role: string;
  status?: string | null;
  indicator?: 'orange' | 'red' | 'gray';
};

export type MemberActual = MemberBase & {
  type: 'subscription';
  data: SubscriptionMember;
};

export type MemberInvitation = MemberBase & {
  type: 'invitation';
  data: SeatInvitation;
};

export type Member = MemberActual | MemberInvitation;

export const isSubscriptionMember = (member: Member): member is MemberActual =>
  member.type === 'subscription';

export const isInvitationMember = (
  member: Member
): member is MemberInvitation => member.type === 'invitation';

export const useMembersList = () => {
  const subscription = useStripeSubscription();
  const members = useSubscriptionMembers();
  const invitations = useInvitations();
  const featureToggles = useFeatureToggles();

  const filteredMembers = useMemo(() => {
    if (featureToggles.EnableUnoccupiedSeats) return members.data ?? [];

    return members.data?.filter((member) => member.email) ?? [];
  }, [members.data]);

  const getInvitationStatus = (invitation: SeatInvitation) => {
    if (invitation.isDeclined) return 'Declined';
    if (invitation.isExpired) return 'Expired';
    return 'Pending';
  };

  const combinedMembersAndInvitations = useMemo<Member[]>(() => {
    const memberList = filteredMembers
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .map<MemberActual>((member) => ({
        id: member.email ?? member.id, // prefer email as id to simplfy tests
        type: 'subscription',
        title: member.email ?? '',
        role: formatRoleNameCapitalized(member.role),
        status: getSeatStatus(member),
        data: member,
      }));

    const invitationList =
      invitations.data?.map<MemberInvitation>((invitation) => ({
        id: invitation.tokenUUID,
        type: 'invitation',
        title: invitation.email ?? 'Invitation link',
        role: 'Member', // TODO: Invitations need to specify a role at some point
        status: getInvitationStatus(invitation),
        indicator: 'gray',
        data: invitation,
      })) ?? [];

    return [...invitationList, ...memberList];
  }, [filteredMembers, invitations.data]);

  return {
    data: combinedMembersAndInvitations,
    isLoading:
      subscription.isPending || invitations.isPending || members.isPending,
    isError: subscription.isError || invitations.isError || members.isError,
  };
};
