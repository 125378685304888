import { useSubscription } from 'src/api/queries';
import { FAQ, FAQLine } from 'src/components';
import { isActiveStripeSubscription } from 'src/utils/subscriptionIsActive';
import { subscriptionIsFreeConnect } from 'src/utils/subscriptionisFreeConnect';
import { UnreachableCodeError } from 'src/utils/unreachableCode';
import { SUPPORT_URL } from 'src/utils/urls/supportUrls';

export enum FAQVariant {
  NO_PLAN,
  FREE_CONNECT,
  CONNECT,
  CONNECT_CANCELLED,
  CONNECT_CONTINUOUS,
}

enum FAQTopic {
  WHAT_IS_CONNECT,
  WHAT_DOES_UNLIMITED_STORAGE_MEAN,
  HOW_DO_I_MANAGE_MY_SUBSCRIPTION,
  WHAT_HAPPENS_IF_I_CANCEL_MY_SUBSCRIPTION,
  CAN_I_USE_CONNECT_ON_ANY_RM_DEVICE,
  WHEN_AND_HOW_OFTEN_WILL_I_BE_CHARGED,
  WHY_WAS_MY_BILLING_DIFFERENT_THAN_I_EXPECTED,
  I_HAVE_CANCELED_MY_SUBSCRIPTION_WHAT_HAPPENS_NOW,
  HOW_DOES_REMARKABLE_2_WORK_WITHOUT_CONNECT,
  HOW_DO_I_SET_UP_CONNECT_DRIVE_DROPBOX_AND_ONEDRIVE,
  HOW_DOES_THE_SATISFACTION_GUARANTEE_WORK,
  WHAT_IS_THE_REMARKABLE_2_PROTECTION_PLAN,
  I_HAVE_PURCHASED_CONNECT_HOW_DO_I_ACTIVATE_IT,
  I_ALREADY_OWN_A_REMARKABLE_CAN_I_STILL_GET_CONNECT,
  DO_YOU_OFFER_ANNUAL_BILLING,
}

type FAQTopics = FAQTopic[];

interface FAQContent {
  text: string | JSX.Element;
  target: string;
}

const mapFAQVariantToFAQTopics = (variant: FAQVariant): FAQTopics => {
  switch (variant) {
    case FAQVariant.CONNECT:
      return [
        FAQTopic.WHAT_IS_CONNECT,
        FAQTopic.DO_YOU_OFFER_ANNUAL_BILLING,
        FAQTopic.WHAT_DOES_UNLIMITED_STORAGE_MEAN,
        FAQTopic.HOW_DO_I_MANAGE_MY_SUBSCRIPTION,
        FAQTopic.WHAT_HAPPENS_IF_I_CANCEL_MY_SUBSCRIPTION,
        FAQTopic.CAN_I_USE_CONNECT_ON_ANY_RM_DEVICE,
        FAQTopic.WHEN_AND_HOW_OFTEN_WILL_I_BE_CHARGED,
        FAQTopic.WHY_WAS_MY_BILLING_DIFFERENT_THAN_I_EXPECTED,
      ];
    case FAQVariant.CONNECT_CANCELLED:
      return [
        FAQTopic.I_HAVE_CANCELED_MY_SUBSCRIPTION_WHAT_HAPPENS_NOW,
        FAQTopic.DO_YOU_OFFER_ANNUAL_BILLING,
        FAQTopic.HOW_DOES_REMARKABLE_2_WORK_WITHOUT_CONNECT,
        FAQTopic.WHAT_IS_CONNECT,
      ];
    case FAQVariant.FREE_CONNECT:
      return [
        FAQTopic.WHAT_IS_CONNECT,
        FAQTopic.CAN_I_USE_CONNECT_ON_ANY_RM_DEVICE,
        FAQTopic.WHAT_DOES_UNLIMITED_STORAGE_MEAN,
      ];
    case FAQVariant.CONNECT_CONTINUOUS:
      return [
        FAQTopic.WHAT_IS_CONNECT,
        FAQTopic.HOW_DO_I_SET_UP_CONNECT_DRIVE_DROPBOX_AND_ONEDRIVE,
        FAQTopic.WHAT_DOES_UNLIMITED_STORAGE_MEAN,
        FAQTopic.HOW_DO_I_MANAGE_MY_SUBSCRIPTION,
        FAQTopic.WHAT_HAPPENS_IF_I_CANCEL_MY_SUBSCRIPTION,
        FAQTopic.CAN_I_USE_CONNECT_ON_ANY_RM_DEVICE,
        FAQTopic.WHEN_AND_HOW_OFTEN_WILL_I_BE_CHARGED,
        FAQTopic.WHY_WAS_MY_BILLING_DIFFERENT_THAN_I_EXPECTED,
        FAQTopic.HOW_DOES_THE_SATISFACTION_GUARANTEE_WORK,
        FAQTopic.WHAT_IS_THE_REMARKABLE_2_PROTECTION_PLAN,
      ];
    case FAQVariant.NO_PLAN:
      return [
        FAQTopic.WHAT_IS_CONNECT,
        FAQTopic.DO_YOU_OFFER_ANNUAL_BILLING,
        FAQTopic.I_HAVE_PURCHASED_CONNECT_HOW_DO_I_ACTIVATE_IT,
        FAQTopic.I_ALREADY_OWN_A_REMARKABLE_CAN_I_STILL_GET_CONNECT,
        FAQTopic.HOW_DOES_REMARKABLE_2_WORK_WITHOUT_CONNECT,
      ];
    default:
      throw new UnreachableCodeError(variant);
  }
};

const mapFAQTopicToFAQContent = (topic: FAQTopic): FAQContent => {
  switch (topic) {
    case FAQTopic.WHAT_IS_CONNECT:
      return {
        text: 'What is Connect?',
        target: SUPPORT_URL.ARTICLES.ABOUT_CONNECT_SUBSCRIPTION,
      };
    case FAQTopic.WHAT_DOES_UNLIMITED_STORAGE_MEAN:
      return {
        text: (
          <span>
            What does <em className="italic">unlimited storage</em> mean?
          </span>
        ),
        target: SUPPORT_URL.ARTICLES.UNLIMITED_STORAGE_MEANS,
      };
    case FAQTopic.HOW_DO_I_MANAGE_MY_SUBSCRIPTION:
      return {
        text: 'How do I manage my subscription?',
        target: SUPPORT_URL.ARTICLES.MANAGE_CONNECT_SUBSCRIPTION,
      };
    case FAQTopic.WHAT_HAPPENS_IF_I_CANCEL_MY_SUBSCRIPTION:
      return {
        text: 'What happens if I cancel my subscription?',
        target: SUPPORT_URL.ARTICLES.CANCEL_CONNECT_SUBSCRIPTION,
      };
    case FAQTopic.CAN_I_USE_CONNECT_ON_ANY_RM_DEVICE:
      return {
        text: 'Can I use Connect on any reMarkable device?',
        target: SUPPORT_URL.ARTICLES.ABOUT_CONNECT_SUBSCRIPTION,
      };
    case FAQTopic.WHEN_AND_HOW_OFTEN_WILL_I_BE_CHARGED:
      return {
        text: 'When and how often will I be charged?',
        target: SUPPORT_URL.ARTICLES.CONNECT_BILLING_DATE,
      };
    case FAQTopic.WHY_WAS_MY_BILLING_DIFFERENT_THAN_I_EXPECTED:
      return {
        text: 'Why was my billing different than I expected?',
        target: SUPPORT_URL.ARTICLES.UNEXPECTED_SUBSCRIPTION_CHARGES,
      };
    case FAQTopic.I_HAVE_CANCELED_MY_SUBSCRIPTION_WHAT_HAPPENS_NOW:
      return {
        text: "I've canceled my subscription, what happens now?",
        target: SUPPORT_URL.ARTICLES.CANCEL_CONNECT_SUBSCRIPTION,
      };
    case FAQTopic.HOW_DOES_REMARKABLE_2_WORK_WITHOUT_CONNECT:
      return {
        text: 'How does reMarkable work without Connect?',
        target: SUPPORT_URL.ARTICLES.USING_REMARKABLE_WITHOUT_CONNECT,
      };
    case FAQTopic.HOW_DO_I_SET_UP_CONNECT_DRIVE_DROPBOX_AND_ONEDRIVE:
      return {
        text: 'How do I set up Google Drive, Dropbox, and OneDrive?',
        target: SUPPORT_URL.ARTICLES.INTEGRATIONS,
      };
    case FAQTopic.HOW_DOES_THE_SATISFACTION_GUARANTEE_WORK:
      return {
        text: 'How does the satisfaction guarantee work?',
        target: SUPPORT_URL.ARTICLES.SATISFACTION_GUARANTEE,
      };
    case FAQTopic.WHAT_IS_THE_REMARKABLE_2_PROTECTION_PLAN:
      return {
        text: 'What is the reMarkable Protection Plan?',
        target: SUPPORT_URL.ARTICLES.ABOUT_THE_REMARKABLE_PROTECTION_PLAN,
      };
    case FAQTopic.I_HAVE_PURCHASED_CONNECT_HOW_DO_I_ACTIVATE_IT:
      return {
        text: "I've purchased Connect, how do I activate it?",
        target: SUPPORT_URL.ARTICLES.ACTIVATE_CONNECT_SUBSCRIPTION,
      };
    case FAQTopic.I_ALREADY_OWN_A_REMARKABLE_CAN_I_STILL_GET_CONNECT:
      return {
        text: 'I already own a reMarkable. Can I still get Connect?',
        target: SUPPORT_URL.ARTICLES.ABOUT_CONNECT_SUBSCRIPTION,
      };
    case FAQTopic.DO_YOU_OFFER_ANNUAL_BILLING:
      return {
        text: 'Do you offer annual billing?',
        target: SUPPORT_URL.ARTICLES.DO_YOU_OFFER_ANNUAL_BILLING,
      };
    default:
      throw new UnreachableCodeError(topic);
  }
};

export const SubscriptionFAQ = ({ variant }: { variant?: FAQVariant }) => {
  const subscription = useSubscription();

  if (!subscription.data) {
    variant ??= FAQVariant.NO_PLAN;
  }

  if (subscription.data?.status === 'canceled') {
    variant ??= FAQVariant.CONNECT_CANCELLED;
  }

  if (isActiveStripeSubscription(subscription.data)) {
    // TODO: Figure out if this still exists and is needed
    // if (subscription.data.type === 'stripe') {
    //   variant ??= FAQVariant.CONNECT_CONTINUOUS;
    // } else {
    variant ??= FAQVariant.CONNECT;
    // }
  }

  if (subscriptionIsFreeConnect(subscription.data)) {
    variant ??= FAQVariant.FREE_CONNECT;
  }

  const topics = mapFAQVariantToFAQTopics(variant ?? FAQVariant.CONNECT);

  return (
    <FAQ>
      {topics.map((topic) => {
        const { text, target } = mapFAQTopicToFAQContent(topic);

        return <FAQLine key={topic} text={text} target={target} />;
      })}
    </FAQ>
  );
};
