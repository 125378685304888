import { Spinner, Typography } from '@remarkable/ark-web';
import { useIsMutating } from '@tanstack/react-query';

import {
  MyRmSubscriptionMembersHandlersTypes,
  StripeSubscription,
} from 'src/api/endpoints/storeApi.types';
import {
  queryKeys,
  useCreateSeatForMember,
  useRemoveSeatForMember,
} from 'src/api/queries';
import { Button, Tooltip } from 'src/components';
import { formatDate } from 'src/utils/formatDate';

export const getSeatStatusText = (
  member: MyRmSubscriptionMembersHandlersTypes.ListMembers.Member,
  subscription?: StripeSubscription | null
) => {
  if (!member.seat) {
    return {
      mainStatus: 'Inactive seat',
    };
  }

  if (subscription?.status === 'unpaid') {
    return {
      mainStatus: 'On hold',
    };
  }

  let subStatus;

  if (member.seat.pendingUntil) {
    subStatus = member.seat.pendingUntil
      ? `Free until ${formatDate(member.seat.pendingUntil)}`
      : 'Free until next billing';
  }

  return {
    mainStatus: 'Active seat',
    subStatus,
  };
};

export const SeatStatus = ({
  member,
  subscription,
}: {
  member: MyRmSubscriptionMembersHandlersTypes.ListMembers.Member;
  subscription?: StripeSubscription | null;
}) => {
  const createSeatForMember = useCreateSeatForMember({ memberId: member.id });
  const removeSeatForMember = useRemoveSeatForMember({ memberId: member.id });
  // TODO: useIsMutating may not be necessary here after React Query V5 upgrade
  const isMutatingCreateSeat =
    useIsMutating({
      mutationKey: queryKeys.subscriptions.createSeatForMember(member.id),
    }) > 0;
  const isMutatingRemoveSeat =
    useIsMutating({
      mutationKey: queryKeys.subscriptions.removeSeatForMember(member.id),
    }) > 0;

  const isPending =
    createSeatForMember.isPending ||
    removeSeatForMember.isPending ||
    isMutatingCreateSeat ||
    isMutatingRemoveSeat;

  if (isPending) {
    return <Spinner size="small" className="text-grayscale-gray-500" />;
  }

  const status = getSeatStatusText(member, subscription);

  if (member.seat) {
    return (
      <div>
        {subscription?.status === 'unpaid' ? (
          <Tooltip
            title="Subscription is on hold"
            side="bottom"
            delay={200}
            disableHoverableContent
          >
            <span>{status.mainStatus}</span>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              <span>
                Has access to subscription
                <br />
                features and benefits.
              </span>
            }
            side="bottom"
            delay={200}
            disableHoverableContent
          >
            <span>{status.mainStatus}</span>
          </Tooltip>
        )}

        {status.subStatus && (
          <Tooltip
            title={
              <span>
                Members who have recently
                <br />
                joined or have a Connect trial.
              </span>
            }
            side="bottom"
            delay={200}
            disableHoverableContent
          >
            <Typography
              variant="interface-xs-regular"
              className="text-grayscale-gray-500"
            >
              {status.subStatus}
            </Typography>
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-start gap-8">
      <Tooltip
        title={
          <span>
            Doesn&apos;t have access to
            <br />
            subscription features.
          </span>
        }
        side="bottom"
        delay={200}
        disableHoverableContent
      >
        <span>{status.mainStatus}</span>
      </Tooltip>
      {member.access.canGiveConnect && (
        <Tooltip
          title={
            <Typography
              variant="body-md-regular"
              className="max-w-[200px] text-white"
            >
              Activates the subscription benefits, such as cloud sync, mobile
              and desktop note taking, and protection plan.
            </Typography>
          }
        >
          <Button
            variant="secondary"
            size="small"
            className="text-neutral-dark-4 shadow-button-faded" // Tuned down look
            disabled={isPending}
            onClick={() => createSeatForMember.mutate({ memberId: member.id })}
          >
            Activate
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
