import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRight } from '@phosphor-icons/react';
import { Typography } from '@remarkable/ark-web';

import { ComponentLocations } from 'src/analytics/trackingTypes';
import {
  useAccountInfoSuspense,
  useSubscriptionSuspense,
} from 'src/api/queries';
import { ConfirmUnlinkModal } from 'src/apps/account/app/components/ConfirmUnlinkModal';
import { EditEmailForm } from 'src/apps/account/app/components/EditEmailForm';
import { EditMultiFactorAuth } from 'src/apps/account/app/components/EditMultiFactorAuth';
import { EditNameForm } from 'src/apps/account/app/components/EditNameForm';
import { EditPassword } from 'src/apps/account/app/components/EditPassword';
import { LinkedAccounts } from 'src/apps/account/app/components/LinkedAccounts';
import {
  Button,
  Divider,
  FaqSection,
  NotificationBox,
  Sheet,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { ChangeToAnnualBanner } from 'src/components/ChangeToAnnualBanner';
import { CONFIG, IS_DEVELOPMENT } from 'src/config';
import { FAQ } from 'src/constants/FAQ';
import { URLS } from 'src/utils/urls/urls';
import { userHelpers } from 'src/utils/userHelpers';

export const Account = () => {
  const { user } = useAuth0();
  const subscriptions = useSubscriptionSuspense();
  const accountInfo = useAccountInfoSuspense();

  if (!user) {
    throw new Error('User not found');
  }

  const isOrgMember = userHelpers.isOrganizationMember(user);
  const isDomainClaimed = userHelpers.isDomainClaimedByConnection(user);
  const isSocial = userHelpers.isSocialUser(user);
  const identities = accountInfo.data?.identities ?? [];
  const canEditPassword = userHelpers.canEditPassword(user, identities);
  const isLinkedToSAML = userHelpers.hasSAMLIdentity(identities);

  return (
    <AppPage.Container>
      <ChangeToAnnualBanner
        className="m-auto mb-56 mt-64 max-w-xl px-16"
        componentLocation={ComponentLocations.NOTIFICATIONS.ACCOUNT_PAGE}
        subscription={subscriptions.data}
      />

      <AppPage.Header
        title="Account settings"
        subtitle="Manage your payment cards, personal contact details, and view your
          account and payment history."
      ></AppPage.Header>
      <AppPage.Content className="max-w-lxl">
        <Sheet className="gap-24 lm:gap-32">
          <div className="flex flex-col gap-24">
            <Typography variant="heading-subtle-2xl" as="h2">
              Contact details
            </Typography>

            <EditNameForm />
            <EditEmailForm />

            {isOrgMember && (
              <NotificationBox
                variant="info"
                muted
                title="This account is managed by an external identity provider.
                    Please contact your IT admin to manage your account."
              />
            )}

            {isSocial && isDomainClaimed && (
              <NotificationBox variant="info" title="Change email address">
                <Typography variant="body-md-regular">
                  You can&apos;t change your email using social login. Contact
                  your IT admin.
                </Typography>
              </NotificationBox>
            )}
          </div>

          <Divider />

          <div className="flex flex-col gap-24">
            <Typography variant="heading-subtle-2xl" as="h2">
              Subscription and payment
            </Typography>

            <div className="flex flex-col justify-between gap-24 lm:flex-row">
              <Typography variant="body-md-regular">
                Start a subscription, or manage existing subscription and
                payments.
                <br />
                Upgrade to multiple subscription management.
              </Typography>

              <Button
                as="a"
                variant="tertiary"
                to={URLS.SUBSCRIPTION}
                size="medium"
                className="w-full self-start whitespace-nowrap ls:w-fit"
              >
                <span>Go to subscription</span> <ArrowRight />
              </Button>
            </div>
          </div>

          {!isLinkedToSAML && (
            <>
              <Divider />

              <div data-cy="security-card" className="flex flex-col gap-24">
                <Typography variant="heading-subtle-2xl" as="h2">
                  Security
                </Typography>

                {canEditPassword && <EditPassword />}

                <EditMultiFactorAuth />
              </div>
            </>
          )}

          {!isOrgMember && IS_DEVELOPMENT && (
            <>
              <Divider />

              <div className="flex flex-col gap-24">
                <Typography variant="heading-subtle-2xl" as="h2">
                  Enterprise single sign-on
                </Typography>

                <div className="flex justify-between gap-16">
                  <Typography variant="body-md-regular">
                    Enroll to enterprise single sign-on for centralised user
                    managemet.
                  </Typography>

                  <Button
                    as="a"
                    to={URLS.ENTERPRISE}
                    variant="tertiary"
                    size="medium"
                  >
                    <span>Start SSO setup</span> <ArrowRight />
                  </Button>
                </div>
              </div>
            </>
          )}

          {!isLinkedToSAML && CONFIG.EnableUnlinkSocialAccount && (
            <>
              <LinkedAccounts />
              <ConfirmUnlinkModal />
            </>
          )}

          {!isLinkedToSAML && (
            <>
              <Divider />
              <div className="flex flex-col gap-24">
                <Typography variant="heading-subtle-2xl" as="h2">
                  Delete account
                </Typography>

                <div className="flex justify-between gap-16">
                  <Typography variant="body-md-regular">
                    Permanently delete your reMarkable account.
                  </Typography>

                  <Button
                    as="a"
                    to={URLS.ACCOUNT_DELETE_LANDING}
                    variant="tertiary"
                    size="medium"
                  >
                    <span>Delete account</span> <ArrowRight />
                  </Button>
                </div>
              </div>
            </>
          )}
        </Sheet>

        {!isLinkedToSAML && (
          <div className="mt-96">
            <FaqSection
              className="my-32"
              title="Frequently asked questions"
              componentLocation={ComponentLocations.ACCOUNT.SECURITY_CARD}
              sections={[
                FAQ.account.whatIsMFA(),
                FAQ.account.whatHappensIfIEnableMFA(),
              ]}
            />
          </div>
        )}
      </AppPage.Content>
    </AppPage.Container>
  );
};
